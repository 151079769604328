<template>
    <div class="printThis">
        <v-container fluid class="mb-10">
            <v-card elevation="0">
              
                <v-card-subtitle class="text-overline text-h5 mb-0 pb-0" style="line-height: 0.95em !important;">Midwest Spring Super Pull (MSSP) 2024<br>REGISTERED: {{ data.fullName }}<br> TRACTOR: {{data.tractor}}<br><br></v-card-subtitle> 
                <v-card-title style="margin-bottom:0.3em;" class="mt-0 pt-0">
                    
                    <h1 style="font-size:0.8em;">Registration Form</h1>
                                                    
                    
                </v-card-title>
               
                    <v-row class="pt-0">
                        <v-col lg="4" md="6" sm="6" cols="12">
                        
                                    <v-list>
                                        <v-subheader style="font-weight: 600 !important; color: maroon !important; font-size: 1.15em !important;">Registration Details</v-subheader>
                                        <v-list-item >
                                            <v-list-item-content>
                                            <v-list-item-title>{{ data.fullName }}</v-list-item-title>
                                            <v-list-item-subtitle>Full Name</v-list-item-subtitle>
                                        </v-list-item-content>
                                        </v-list-item>
                                        <v-list-item >
                                            <v-list-item-content>
                                            <v-list-item-title>{{ data.age }}</v-list-item-title>
                                            <v-list-item-subtitle>Age</v-list-item-subtitle>
                                        </v-list-item-content>
                                        </v-list-item>
                                        <v-list-item >
                                            <v-list-item-content>
                                            <v-list-item-title>{{ data.birthdate }}</v-list-item-title>
                                            <v-list-item-subtitle>Birthdate</v-list-item-subtitle>
                                        </v-list-item-content>
                                        </v-list-item>
                                        <v-list-item >
                                            <v-list-item-content>
                                            <v-list-item-title>{{ data.mailingAddress }}, {{ data.city }} {{ data.state }} {{ data.postalCode }}</v-list-item-title>
                                            <v-list-item-subtitle>Mailing Address</v-list-item-subtitle>
                                        </v-list-item-content>
                                        </v-list-item>
                                        <v-list-item >
                                            <v-list-item-content>
                                            <v-list-item-title>{{ data.email }}</v-list-item-title>
                                            <v-list-item-subtitle>Email</v-list-item-subtitle>
                                        </v-list-item-content>
                                        </v-list-item>
                                        <v-list-item >
                                            <v-list-item-content>
                                            <v-list-item-title>{{ data.telephone }}</v-list-item-title>
                                            <v-list-item-subtitle>Telephone</v-list-item-subtitle>
                                        </v-list-item-content>
                                        </v-list-item>
                                        </v-list>
                                        
                        
                
                                </v-col>
                            <v-col lg="4" md="6" sm="6" cols="12">
                                <v-list>
                                        <v-subheader style="font-weight: 600 !important; color: maroon !important; font-size: 1.15em !important;">Vehicle Details</v-subheader>
                                        <v-list-item >
                                            <v-list-item-content>
                                            <v-list-item-title>{{ data.driver }}</v-list-item-title>
                                            <v-list-item-subtitle>Driver</v-list-item-subtitle>
                                        </v-list-item-content>
                                        </v-list-item>
                                        <v-list-item >
                                            <v-list-item-content>
                                            <v-list-item-title>{{ data.tractor }}</v-list-item-title>
                                            <v-list-item-subtitle>Tractor Name</v-list-item-subtitle>
                                        </v-list-item-content>
                                        </v-list-item>
                                        <v-list-item >
                                            <v-list-item-content>
                                            <v-list-item-title>{{ data.tractorMake }}</v-list-item-title>
                                            <v-list-item-subtitle>Tractor Make/Model</v-list-item-subtitle>
                                        </v-list-item-content>
                                        </v-list-item>
                                        <v-list-item >
                                            <v-list-item-content>
                                            <v-list-item-title>{{ data.engineBrand }}</v-list-item-title>
                                            <v-list-item-subtitle>Engine Brand</v-list-item-subtitle>
                                        </v-list-item-content>
                                        </v-list-item>
                                        <v-list-item >
                                            <v-list-item-content>
                                            <v-list-item-title>{{ data.engineType }}</v-list-item-title>
                                            <v-list-item-subtitle>Engine Type</v-list-item-subtitle>
                                        </v-list-item-content>
                                        </v-list-item>

                                    
                                    </v-list>
                            </v-col>
                            <v-col lg="4" md="6" sm="6" cols="12">
                                <v-list>
                                        <v-subheader style="font-weight: 600 !important; color: maroon !important; font-size: 1.15em !important;">Class Selection</v-subheader>
                                        <v-list-item >
                                            <v-list-item-content>
                                            <v-list-item-title><span v-for="(key) in data.selectedClassesFriday" :key="key">{{ key }} <br/></span></v-list-item-title>
                                            <v-list-item-subtitle>Friday</v-list-item-subtitle>
                                        </v-list-item-content>
                                        </v-list-item>
                                        <v-list-item >
                                            <v-list-item-content>
                                            <v-list-item-title><span v-for="(key) in data.selectedClassesSaturday" :key="key">{{ key }} <br/></span></v-list-item-title>
                                            <v-list-item-subtitle>Saturday</v-list-item-subtitle>
                                        </v-list-item-content>
                                        </v-list-item>
                                        <v-subheader style="font-weight: 600 !important; color: maroon !important; font-size: 1.15em !important;">Cost</v-subheader>
                                        
                                        <v-list-item v-if="data.cost.standard">
                                            <v-list-item-content>
                                            <v-list-item-title>${{ data.cost.standard }}</v-list-item-title>
                                            <v-list-item-subtitle>Standard</v-list-item-subtitle>
                                        </v-list-item-content>
                                        </v-list-item>
                                        <v-list-item v-if="data.cost.reduced">
                                            <v-list-item-content>
                                            <v-list-item-title>${{ data.cost.reduced }}</v-list-item-title>
                                            <v-list-item-subtitle>Reduced (Youth Stock, OTL)</v-list-item-subtitle>
                                        </v-list-item-content>
                                        </v-list-item>
                                        <v-list-item v-if="data.cost.total">
                                            <v-list-item-content>
                                            <v-list-item-title>${{ data.cost.total }}</v-list-item-title>
                                            <v-list-item-subtitle>Total</v-list-item-subtitle>
                                            <div>Please make checks out to Midwest Super Pull</div>
                                        </v-list-item-content>
                                        </v-list-item>
                                        

                                    
                                    </v-list>
                            </v-col>
                            </v-row>
                            <v-row>
                                <!--
                                <v-col>
                                    <div class="text-h5" style="font-size:1.1em !important;">
                                        Mail to <strong>Midwest Spring Super Pull</strong>, c/o Matt Larsen, 26696 390th St, Le Sueur, MN 56058 
                                    </div>
                                   
                                    <div class="">To qualify for pre-entry fee form must be postmarked by March 25th 2024.</div>
                <div class="mb-10">Entry fee after March 25th increases to $60 per class.</div>
                
                                </v-col>-->
                            </v-row>
            </v-card>
        </v-container>
    
    </div>
</template>

<script>
import {db, classes,users} from '../../firebaseconfig'
import _ from 'lodash'
import { QuerySnapshot } from '@firebase/firestore'
export default {
    name: 'printForm',
    data() {
        return {
            id: 1,
            print: this.data
        }
    },
    firestore() {
        return {
            
        }
    },
    computed: {
         data() {
            return JSON.parse(localStorage.getItem('printForm'))
        },
    },
        methods: {
            
        }

    
}
</script>

<style>
@media print {
    header, footer {
        display:none
    }
    
    body {
      overflow: auto;
      height: auto;
      max-width: 100vw;
      max-height: fit-content;
      display: block;
      font-size: 0.8em !important;
      margin-top:-100px !important;
    }

    .v-container {
        margin-top:-100px !important;
    }

    .pgBreak {
      page-break-after: always;
      margin-top: 0px;
    }

    .text-h5 {
        font-size:0.85em !important;
    }

    .no-print {
      display: none !important;
    }

    .pullDetails {
      position: fixed;
      top: 0;
      width: 100%;
    }

  }
</style>

