<template>
    <div>
        <v-dialog v-model="show" width="387">
    <template v-slot:activator="{ on, attrs }">
        <v-btn text v-bind="attrs" v-on="on">Add Payment</v-btn>
    </template>
    <v-card><v-card-title>Add Payment</v-card-title>
        <v-card-text>
        <v-row v-if="hooks.st_qt > 0">
            
            <v-col cols="12">
                
                    <div v-if="preOrderPricing">{{hooks.st_qt}} standard hooks x ${{prices.preOrder.standard}} = ${{form.standardPre}}</div>
                    <div v-else>{{hooks.st_qt}} standard hooks x ${{prices.full.standard}} = ${{form.standardFull}}</div>
                
            </v-col>
        </v-row>
        <v-row v-if="hooks.rd_qt > 0">
            <v-col cols="12">
                
                    <div v-if="preOrderPricing">{{hooks.rd_qt}} reduced hooks x ${{prices.preOrder.reduced}} = ${{form.reducedPre}}</div>
                    <div v-else>{{hooks.rd_qt}} reduced hooks x ${{prices.full.reduced}} = ${{form.reducedFull}}</div>
                    
            </v-col>
        </v-row>
        <v-row v-if="previousPayment > 0" style="margin-top:-20px;">
            <v-col>Previous payment: ${{previousPayment}}</v-col>
        </v-row>
        <v-row style="margin-top:-20px;">
            <v-col cols="12">Payment due: ${{paymentDue}}</v-col>
            <v-col cols="12">
                <v-text-field label="Payment Received" v-model.number="payment" prefix="$"></v-text-field>
            </v-col>
        </v-row>
        </v-card-text>
    <v-card-actions>
        <v-col>
        <v-btn text @click="show = false" block>Cancel</v-btn>
        </v-col><v-col><v-btn @click="createSession(hooks,costs,user,payment)" block color="primary">Add Payment</v-btn>
        </v-col></v-card-actions>
    </v-card>
</v-dialog>
    </div>
</template>
<script>
    import _ from 'lodash'
    import {
        db
    } from "../../firebaseconfig"

export default {
    name: 'addPayment',
    data() {
        let a = this.paymentDue
        return {
            show: false,
            performingRequest: false,
            form: {
                reducedPre: this.hooks.rd_qt * this.prices.preOrder.reduced,
                reducedFull: this.hooks.rd_qt * this.prices.full.reduced,
                standardPre: this.hooks.st_qt*this.prices.preOrder.standard,
                standardFull: this.hooks.st_qt*this.costs.full.standard
            },
            payment: 0
        }
    },
    props: {
        classes: Array,
        user: String,
        hooks: Object,
        costs: Object,
        preOrderPricing: Boolean,
        prices: Object,
        previousPayment: Number
    },
    computed: {
        paymentDue(){
            let f = this.form
            if (this.preOrderPricing && this.previousPayment) {
                return ((f.reducedPre + f.standardPre)-this.previousPayment)
            } else if (this.previousPayment) {
                return (f.reducedFull + f.standardFull) - this.previousPayment
            } else if (this.preOrderPricing) {
                return f.reducedPre + f.standardPre
            } else {
                return f.standardPre + f.standardFull
            }
        }
    },
    methods: {
       createSession(hooks,costs,user,payment, by) {
           let lineItems = []
           
           if (hooks.rd_qt > 0  & this.preOrderPricing == true) {
               lineItems.push(
                   {
                       price: this.prices.preOrder.reduced,
                       cost: this.form.reducedPre,
                       quantity: hooks.rd_qt
                   }
               )
           } else if (hooks.rd_qt > 0  & this.preOrderPricing == false) {
               lineItems.push(
                   {
                       price: this.prices.full.reduced,
                       cost: this.form.reducedFull,
                       quantity: hooks.rd_qt
                   }
               )}
       
       if (hooks.st_qt > 0  & this.preOrderPricing == true) {
               lineItems.push(
                   {
                       price: this.prices.preOrder.standard,
                       cost: this.form.standardPre,
                       quantity: hooks.st_qt
                   }
               )
            } else if (hooks.st_qt > 0  & this.preOrderPricing == false) {
               lineItems.push(
                   {
                       price: this.prices.full.standard,
                       cost: this.form.standardFull,
                       quantity: hooks.st_qt
                   }
               )
            }
        
            let checkout_session = {
                hooks: hooks,
                lineItems: lineItems,
                mode: 'payment',
                paid: this.payment,
                timestamp: new Date(),
                user: user
            }

            console.log(checkout_session)

            
            const docRef = db.collection('users').doc(user)
                .collection('checkout_sessions')
                .add(checkout_session)
                .then(function(a){
                    console.log(a.data)
                    let firstday = new Date("2023-01-01")
                    let sessions = []
                    let sessionValue
                    let sessionSummary = []
                    
                    
                    const sessionRef = db.collection("users").doc(user).collection("checkout_sessions").where("timestamp",">=",firstday)
                        .get()
                        .then((querySnapshot) => {
                            querySnapshot.forEach((doc)=> {
                                sessions.push(doc.data())
                            })

                            sessionValue = _.sumBy(sessions, function(p){
                                return p.paid
                            })

                            sessionSummary = {
                                checkoutSessions: sessions.length,
                                paid: sessionValue,
                                sessions: sessions
                            }

                            db.collection("users").doc(user).update({
                                year_2023: sessionSummary
                            })
                        })
                })

                this.payment = 0
                this.show = false
                   

            // emit output to trigger hook creation
            this.$emit("paid")

        }
    }
}
</script>