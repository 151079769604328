<template id="mail">
    <div class="mt-5 mb-5">
        
        <v-col cols=12 sm=9 md=6 class="mx-auto">
          <h1 style="line-height:0.9em; color:#BF360C" class="">Subscribe to our newsletter</h1>
          <p class="mt-2">Stay up to date on the latest information with the Midwest Spring Super Pull newsletter.</p>
            <v-text-field color="red darken-3" v-model="email" label="Email address" placeholder="name@email.com" hint="You can only sign up for the newsletter once." :success="success" :error="error"></v-text-field>
            <v-alert v-if="error" color="alert">{{errorMessage}}</v-alert>
            <v-alert outlined v-if="success" color="success">You're signed up! We're excited to share what's going on in MSSP with you.</v-alert>
            <v-btn color="red darken-3" dark class="mt-2" @click="addSubscriber">Subscribe</v-btn>
        </v-col>
    </div>
</template>

<script>
import {db} from '../../firebaseconfig'

export default {
    name: 'mailing',
    data() {
        return {
            email: '',
            success: null,
            error: null,
            errorMessage: ''
        }
    },
    methods: {
        addSubscriber: function() {
            let mid
            let e = this.email
            let s = this.success
            let er = this.error
            let erm = this.errorMessage
            db.collection("subscribers").doc(e).set({
                emailAddress: this.email,
                created: new Date()
            }).then(
                () => {
                    console.log("Subscription created!")
                    this.success = true
                }
            ).catch(err => {
               erm = err.message
               er = true
                s = false
            })
        }
    }
}
</script>
