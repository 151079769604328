<template>
    <div class="overflow-hidden">
        <div id="loc"></div>
        <v-container fluid>
            
            <v-row>
                <v-col cols=12 xs=12 sm=6 class="d-flex align-center">
                    <v-img src="../assets/cedar-lake-arena-logo.jpg"  class="fluid align-self-center mx-auto">
                    </v-img>
                </v-col>
                <v-col cols=12 xs=12 sm=6>
                    <h2>Cedar Lake Arena</h2>
                    <p style="margin-top:-10px;"><small>New Richmond, Wisconsion</small></p>
                    <p>Home to a competitive motocross track, this indoor arena boasts a dirt track that few facilities in the midwest can offer. At the Midwest Spring Super Pull, we run two parallel tracks of more than 280 ft., running multiple classes and vehicles at one time.</p>
                    <p>The Arena hosts a variety of food and beverage stands. Outside food and beverage is prohibited.</p>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>
<script>
export default {
    name: 'location'
}
</script>

<style>
#loc {
   background:linear-gradient(145deg, rgba(197, 29, 29, 0.805), rgba(247, 128, 108, 0.5)), url(https://firebasestorage.googleapis.com/v0/b/midwestsuperpull.appspot.com/o/arena%20shot.jpg?alt=media&token=890b8b18-9ca3-4488-9dd0-8c0aa91f6b0f) no-repeat center center fixed; 
   filter: contrast(80%);
   height: 3em;
   width: 100%;
   min-height:200px;
   background-attachment: fixed;
   background-position:top-left;
   background-repeat:no-repeat;
   background-size:cover;
   margin-bottom:2em;
}

</style>