<template>
<div>
    <div class="hero" :data-date="now" height="500px" width="100%" >
  <video class="hero-video" autoplay muted loop height="500px" width="100%" style="z-index: 1; background-color: darkred;">
    <source :src="videoSRC" type="video/mp4">
  </video>
    <div style="background-color: rgba(255,77,45,0.5); opacity:0;">

    </div>
  </div>

<div id="masthead" class="fluid d-flex justify-center align-center" height="500px" style="z-index:2;  ">
    <div style="background-color: brown;;">
    <v-card class="justify-center" color="transparent" flat dark style="margin-top:50px;">
        <v-card-title style="line-height:1.9em;"><h1>Midwest <br class="hidden-sm-and-up">Spring<br class="hidden-md-and-up"> Super Pull</h1></v-card-title>
        <v-card-subtitle id="sub1">The largest tractor pull <br class="hidden-sm-and-up">in the Midwest</v-card-subtitle>
        <v-card-text>
            <div id="head">
                
                April 5-6, 2024 <br>Cedar Lake Speedway Indoor Arena<br>
                <!--Pre-registration closes March 25, 2024.-->
            </div>
            
        </v-card-text>
        <v-card-actions>
            <v-row>
        <v-btn class="" dark color="red darken-3" >See you next year!</v-btn>
            </v-row>
            <v-row>
        
            </v-row>
        </v-card-actions>
    </v-card>
</div>
</div> 
<v-container style="z-index: 1; margin-top: 50px; ">
 <v-row align="center" justify="center">
    <v-col lg=4 class="mx-auto text-center">
            <v-card outlined  fill-height min-width="200px" >
                <v-card-text class="text-center">
                    <div class="text-h4">2 DAYS</div>
                    <div class="">April 5-6, 2024</div>
                </v-card-text>
            </v-card>
    </v-col>
    <v-col lg=4 class="mx-auto text-center">
            <v-card outlined min-width="200px">
                <v-card-text class="text-center">
                    <div class="text-h4">2 TRACKS</div>
                    <div class="">Indoors, 260 ft. each</div>
                </v-card-text>
            </v-card>
    </v-col>
    <v-col lg=4 class="mx-auto text-center" >
            <v-card outlined min-width="200px">
                <v-card-text class="text-center">
                    <div class="text-h4">20 CLASSES</div>
                    <div class="">Youth -> 1950 Mini Rods</div>
                </v-card-text>
            </v-card>
    </v-col>
        
    </v-row>
    <v-row class="align-right">
        <v-col cols="12" sm="12">
            <v-card flat class="text-center">
                
                <v-card-text style="font-size:1em;">
                <v-row>
                    <v-col v-for="d in datesTimes" :key="d.date">
                    <div class="overline">{{ currentDate(d.date)  }}</div>           
                    <div v-if="d.tech">
                        {{ d.tech.name }} {{ d.tech.startTime }} - {{ d.tech.endTime }}
                    </div>
                    <div v-if="d.testHooks">
                        {{ d.testHooks.name }} {{ d.testHooks.startTime }}
                    </div>
                    <div v-if="d.pullingStart">
                        {{ d.pullingStart.name }} {{ d.pullingStart.startTime }}
                    </div>
                </v-col>
                </v-row>
              
                </v-card-text>
            </v-card>
        </v-col>
    </v-row>
</v-container>
</div>   
</template>

<script>
import {db, rules} from '../../firebaseconfig'
import { Timestamp } from '@firebase/firestore'
 
export default {
    name: 'hero',
    data(){
        return {
            registration:'',
            datesTimes: [],
            videoSRC: "https://firebasestorage.googleapis.com/v0/b/midwestsuperpull.appspot.com/o/mssp2024-v2.mp4?alt=media&token=901703c8-b73b-4397-9be9-5923436505b6"
        }
    },
    firestore() {
        return {
            registration: db.collection("registration").doc("open"),
            datesTimes: db.collection("datesTimes").where("date",">=","2024-04-01").orderBy("date","asc")
    }},
    methods:{
        currentDate(day){
            let d = new Date(day)
            d.setDate(d.getDate()+1)
            console.log(d,"date")
            console.log(d.toLocaleDateString('en-us',{timeZone:'America/Chicago'},{weekday:"long", year:"numeric", month:"long", day:"numeric"}))
            return d.toLocaleDateString('en-us',{timeZone:'America/Chicago',weekday:"long", year:"numeric", month:"long", day:"numeric"})
        }
    },
    computed: {
      registrationForm: function(){
        return this.registration.form
      },
      registrationClosed: function(){
        return true
      },

      height: function () {
        switch (this.$vuetify.breakpoint.name) {
          case 'xs': return 100
          case 'sm': return 125
          case 'md': return 150
          case 'lg': return 175
          case 'xl': return 200
        }},
        now: function() {
            return new Date();
      },
    },
}
</script>

<style>
template {
    height: 100%
}

.hero {
  position: absolute;
  margin-top:0px;
  width: 100%;
  overflow: hidden;
}

/* Video element */
.hero-video {
  top: 50;
  left: 0;
  z-index: -1;
  object-fit: cover;
}

#masthead {
    background:linear-gradient(to right,rgba(255, 0, 255, 0.4),rgba(179, 65, 67, 0.3));
}

h1 {
    font-size: 2.5em !important;
}

#sub1 {
    text-transform: uppercase;
    font-size:1.25em;
    margin-top:0.1em;
}
</style>