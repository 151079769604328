<template>
<div>
<v-dialog v-model="show" width="387">
    <template v-slot:activator="{ on, attrs }">
        <v-btn text v-bind="attrs" v-on="on"><v-icon>mdi-account-plus-outline</v-icon></v-btn>
    </template>
    <v-card><v-card-title>Add User</v-card-title>
    <v-card-text>
        <v-text-field v-model="form.firstName" label="First Name"></v-text-field>
        <v-text-field v-model="form.lastName" label="Last Name"></v-text-field>
        <v-text-field v-model="form.email" label="Email"></v-text-field>
    </v-card-text>
    <v-card-actions><v-btn @click="create" :loading="loading">Create</v-btn></v-card-actions>
    </v-card>
</v-dialog>
</div>
</template>
<script>
import {db} from '../../firebaseconfig'
export default {
    name: 'addUser',
    data() {
        return {
            show: false,
            loading: false,
            form: {
                firstName: null,
                lastName: null,
                email: null
            }
        }
    },
    methods: {
        
            create: function() {
                console.log("start function to add")
                this.loading = true
                let dR
                db.collection('users').add({
                    firstName: this.form.firstName,
                    lastName: this.form.lastName,
                    email: this.form.email,
                    year_2023: {
                        paid: 0,
                        checkoutSessions:0
                    },
                    created: new Date(),
                }).then((docRef) => {
                    dR = docRef
                    console.log("docRef",dR)
                    this.show = !this.show
                    alert("User, "+ docRef.value()+"successfully created")
                    this.form.firstName == null
                    this.form.lastName == null
                    this.form.email == null
                    //dR = docRef
                    
                }).catch((error) => {
                    console.log("error adding document: ", error)
                    dR = error
                })

                this.loading = false
            }
        
    }
}
</script>