<template>
    <div>
        <v-container>
            <h1>Registration Form</h1>
            <div class="mb-5"><v-chip color="red" label>
                <v-icon left>mdi-cash-clock</v-icon>
                Pre-registration is closed.</v-chip> <small>Complete this form and bring it with you to the event to speed in person registration.</small> </div>
            <div class="mb-3">Use this optional form to help us speed up registration and plan for capacity. <strong>If you prefer, you can download and <a :href="paperForm" style="color:red;" target="_blank">print this form</a> to fill it out offline.</strong></div>
            <div>Please submit one form per vehicle. You'll be prompted at the end of the form if you have multiple tractors. When you submit the form, you'll receive an email copy if you are unable to print.</div>
        </v-container>
        <v-container class="py-0">
            <div class="mt-3 text-h5">Registration Details</div>
            <v-row>
                <v-col cols="12">
                    <v-text-field label="Full Name" v-model="fullName"></v-text-field>
                </v-col>
                <v-col cols="6">
                    <v-text-field label="Age" v-model="age" dense></v-text-field>
                </v-col>
                <v-col cols="6">
                    <v-text-field label="Birthdate" v-model="birthdate" dense></v-text-field>
                </v-col>
                <v-col cols="12">
                    <v-text-field label="Mailing Address" v-model="mailingAddress" dense></v-text-field>
                </v-col>
                <v-col cols="4">
                    <v-text-field label="City" v-model="city" dense></v-text-field>
                </v-col>
                <v-col cols="4">
                    <v-text-field label="State" v-model="state" dense></v-text-field>
                </v-col>
                <v-col cols="4">
                    <v-text-field label="Zip/Postal Code" v-model="postalCode" dense></v-text-field>
                </v-col>
                <v-col cols="6">
                    <v-text-field label="Phone" v-model="telephone" dense></v-text-field>
                </v-col>
                <v-col cols="6">
                    <v-text-field label="Email" v-model="email" dense></v-text-field>
                </v-col>
            </v-row>
        </v-container>
        <v-container>
            <div class="mt-3 text-h5">Vehicle Details</div>
            <v-text-field label="Driver" v-model="driver"></v-text-field>
            <v-text-field label="Tractor Name" v-model="tractor"></v-text-field>
            <v-text-field label="Tractor Make/Model" v-model="tractorMake"></v-text-field>
            <v-text-field label="Engine Brand" v-model="engineBrand"></v-text-field>
            <v-radio-group label="Garden Tractors Must Select One Engine Type" v-model="engineType" dense row style="font-size: 1.3em !important;">
                <v-radio value="Single" label="Single"></v-radio>
                <v-radio value="Flat Twin" label="Flat Twin"></v-radio>
                <v-radio value="V-Twin" label="V-Twin"></v-radio>
                <v-radio value="N/A" label="N/A"></v-radio>
            </v-radio-group>
        </v-container>
        <v-container>
            <h2>Fee Details</h2>
            <div>Entry fee $60 per class (unless noted below)</div>
            <div>Entry includes:</div>
            <ul>    
                <li>1 driver pit pass</li>
                <li>1 additional pit pass per tractor</li>
            </ul>
            <div>Additional pit passes will be available for purchase at the Registration Table.</div>
            <div>Pulling starts at 10am on Friday, 10 am Saturday.</div>
            <div>Registration will close 1 hour prior to the start time.</div>
        </v-container>
        <v-container>
            <h2>Class Selection</h2>
            <div>
                
            <v-row>
                <v-col cols="6">
                    <h3>Friday April 5th</h3>
                <div>Please check ALL appropriate classes for <span v-if="tractor == ''"> your tractor </span> <span v-else> {{ tractor }} </span> on Friday, April 5th.</div>
                    <v-checkbox v-for="f in friday" :key="f.id" :label="f.name" :value="f.id" v-model="selectedClassesFriday" multiple dense :messages="priceHint(f.price)"></v-checkbox>
                </v-col>
                <v-col cols="6">
                    <h3>Saturday April 6th</h3>
                <div>Please check ALL appropriate classes for {{ tractor }} on Saturday, April 6th.</div>
                    <v-checkbox v-for="s in saturday" :key="s.id" :label="s.name" :messages="priceHint(s.price)" :value="s.id" v-model="selectedClassesSaturday" dense></v-checkbox>
                    
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12" >
                    <v-card>
                        <v-card-text>
                            <v-card-title><h2>Summary and Cost</h2></v-card-title>
                        <div class="mx-5 pb-5 text-h6">
                            <div>
                            You selected {{ countFriday }} classes on Friday and {{ countSaturday }} on Saturday. You selected ${{ cost.reduced }} reduced fee and ${{cost.standard}} standard fee classes for a total of ${{ cost.total }}.
                        </div>

                        <div class="mt-3">Mail registration form and payment of <span style="font-weight: 800; color:red;">${{ cost.total }}</span> to:</div>
                        <div class="ml-5">
                            Midwest Spring Super Pull<br>
                            c/o Matt Larsen<br>
                            26696 390th St<br>
                            Le Sueur, MN 56058 
                        </div>
                        </div>
                        
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
            <v-row class="mb-10">
                
                <v-col cols="12">
                    <!--<p>You still need to SEND your completed form and payment to hold your place. If you've provided your email address, a copy of your completed form will be emailed to you as well. </p>-->
                    <p>Planning on same day registration? Complete and print this form to bring with you.</p>
                    <v-row class="">
                        <v-col cols="1"><v-checkbox v-model="multipleTractors" dense></v-checkbox></v-col>
                        <v-col cols="11"><p class="mt-2">Please check the box if you have multiple tractors and wish to fill-out another form.</p></v-col>
                    </v-row>
                <!--
                    <v-row class="mt-0 pt-0" style="margin-top:-35px !important;">
                        <v-col cols="1"><v-checkbox v-model="requestDigitalInvoice" dense></v-checkbox></v-col>
                        <v-col cols="11"><p class="mt-2">Please check the box if you would prefer to receive a digital invoice to pay online (3.5% price increase for payment processor) instead of through the mail.</p></v-col>
                    </v-row>
                -->    
                </v-col>
                
                
                <v-col>
                    <v-btn block text>Cancel</v-btn>
                </v-col>
                <v-col>
                    <v-btn block color="green" @click="printAndSubmit">Print</v-btn>
                </v-col>
                
            </v-row>

            </div>
        </v-container>

    </div>
</template>

<script>
import _ from 'lodash' 
import {db, classes, saturday } from '../../firebaseconfig.js'
import {Timestamp, doc, setDoc, addDoc, collection} from 'firebase/firestore';
//const validators = { required, maxLength, email }
//const validationMixin = vuelidate.validationMixin

export default {
    name: 'register1',
    //mixins: [validationMixin],
    validations: {
        //name: {required, maxLength:maxLength(10), minLength: minLength(3)},
        //emailAddress: {required, email}
    },
    data() {
        return {
            paperForm: 'https://firebasestorage.googleapis.com/v0/b/midwestsuperpull.appspot.com/o/MSSP%202024%20ENTRY%20FORM.pdf?alt=media&token=f10b9903-3158-4bfc-8f80-c726dd151eba',
            fullName: '',
            age: '',
            birthdate: '',
            mailingAddress: '',
            city: '',
            state: '',
            postalCode: '',
            telephone: '',
            email: '',
            driver: '',
            tractor: '',
            tractorMake: '',
            engineBrand: '',
            engineType: '',
            vehicleClasses: classes,
            selectedClassesFriday:[],
            selectedClassesSaturday:[],
            multipleTractors: 0,
            requestDigitalInvoice: false,
            now: new Date()
        }
    },
    firestore(){
        return {
            classes: db.collection("classes")
        }
    },
    methods: {
        priceHint(price){
            if(price == "price/reduced") {return "Youth class fee $35"}
            else {return null}
        },    
        filteredClasses(day, classes, vh){
            let a = _.forEach(day, function(c){
                let filterObject = _.findLast(vh, function(create){
                    return create.id == c
                })
                classes.push(filterObject)
            })
        },
        printAndSubmit(){
            let docContent = {
                fullName: this.fullName,
                age: this.age,
                birthdate: this.birthdate,
                mailingAddress: this.mailingAddress,
                city: this.city,
                state: this.state,
                postalCode: this.postalCode,
                telephone: this.telephone,
                email: this.email,
                driver: this.driver,
                tractor: this.tractor,
                tractorMake: this.tractorMake,
                engineBrand: this.engineBrand,
                engineType: this.engineType,
                selectedClassesFriday: this.selectedClassesFriday,
                selectedClassesSaturday: this.selectedClassesSaturday,
                cost: this.cost,
                eventYear: 2024,
                paid: false, 
                requestDigitalInvoice: this.requestDigitalInvoice,
                created: this.now,
                to: [this.email],
                message: {
                    subject: 'Your 2024 Midwest Spring Super Pull Registration',
                    text: "You have started the process to register for the 2024 Midwest Spring Super Pull for "+this.tractor+". \n\n Full name: "+this.fullName+"\n Age: "+this.age,
                    html: this.html
                }
                


            }

            const docRef = addDoc(collection(db,"mailRegistrationForms"), docContent)
             docRef
             let printData = docContent
             let jsonData = JSON.stringify(printData)
             console.log('DocContent', jsonData)
             localStorage.setItem('printForm',jsonData)
             console.log('Local storage set!')
             
             window.open(this.$router.resolve({ name: 'printForm'}).href, '_blank')
             console.log('New window opened!')
             if (!this.multipleTractors) {
                    this.fullName = '',
                    this.age = '',
                    this.birthdate = '',
                    this.mailingAddress= '',
                    this.city= '',
                    this.state= '',
                    this.postalCode= '',
                    this.telephone='',
                    this.email= '',
                    this.driver= '',
                    this.tractor= '',
                    this.tractorMake= '',
                    this.engineBrand= '',
                    this.engineType= '',
                    this.selectedClassesFriday=[],
                    this.selectedClassesSaturday=[],
                    this.multipleTractors=0,
                    this.requestDigitalInvoice=false
                }
                else {
                    this.tractor= '',
                    this.tractorMake= '',
                    this.engineBrand= '',
                    this.engineType= '',
                    this.selectedClassesFriday=[],
                    this.selectedClassesSaturday=[],
                    this.multipleTractors=0,
                    this.requestDigitalInvoice=false
                }
        }
    },
    computed: {
        html() {
            let a = `
            <div>
                <p>You've started the process to register your tractor, ${this.tractor} ,for the 2024 Midwest Spring Super Pull! Bring this form with you to the pull! Pre-registration is closed.</p>
                <h1>2024 Midwest Spring Super Pull</h1>
                <h2>Registration Form</h2>
                <h3>Registration Details</h3>
                <ul style="list-style:none;">
                    <li>Full name: ${this.fullName}</li>
                    <li>Age: ${this.age}</li>
                    <li>Birthdate: ${this.birthdate}</li>
                    <li>Mailing Address: ${this.mailingAddress}, ${this.city} ${this.state} ${this.postalCode}</li>
                    <li>Email: ${this.email}</li>
                    <li>Telephone: ${this.telephone}</li>
                </ul>
                <h3>Vehicle Details</h3>
                <ul style="list-style:none;">
                    <li>Driver: ${this.driver}</li>
                    <li>Tractor Name: ${this.tractor}</li>
                    <li>Tractor Make/Model: ${this.tractorMake}</li>
                    <li>Engine Brand: ${this.engineBrand}</li>
                    <li>Engine Type: ${this.engineType} </li>
                </ul>
                <h3>Class Selection</h3>
                <ul style="list-style:none;">
                    <li>Friday: ${this.selectedClassesFriday}</li>
                    <li>Saturday: ${this.selectedClassesSaturday}</li>
                </ul>
                <h3>Cost</h3>
                <p>Total: $ ${this.cost.total}</p>
                <p>Make checks out <strong>Midwest Super Pull</strong>.</p>
                <div>
                    <h3>Mail to:</h3>
                    <ul style="list-style:none;">
                    <li>Midwest Spring Super Pull</li>
                    <li>c/o Matt Larsen</li>
                    <li>26696 390th St</li>
                    <li>Le Sueur, MN 56058</li>
                </ul>
                </div>
                <div>To qualify for pre-entry fee form must have been postmarked by March 25th 2024.</div>
                <div>Entry fee after March 25th increased to $60 per class.</div>
            </div>`
            return a
        },
        friday() {
            return _.filter(this.vehicleClasses, function(a){return a.friday })
        },
        saturday() {
            return _.filter(this.vehicleClasses, function(a){return a.saturday })
        },
        countFriday(){
            return this.selectedClassesFriday.length
        },
        countSaturday(){
            return this.selectedClassesSaturday.length
        },
        cost(){
            let fri = this.selectedClassesFriday
            let sat = this.selectedClassesSaturday
            let classes = []

            this.filteredClasses(fri, classes, this.vehicleClasses)
            this.filteredClasses(sat, classes, this.vehicleClasses)

            let costGroup = _.groupBy(classes, function(price){
                return price.reduced
            })

            let reduced = 0
            let standard =0
            if (costGroup.true) {reduced = costGroup.true.length *35} else {reduced = 0}
            if (costGroup.undefined) {standard = costGroup.undefined.length*60} else {standard = 0}

            let total = reduced+standard


            let cost = 
            {
                reduced: reduced,
                standard: standard,
                total: total
            }

            return cost
            
        }
    }
}
</script>
