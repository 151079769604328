<template>
  <div>
        <v-navigation-drawer v-model="drawer" width="400px" fixed style="left:0"
      bottom
      temporary>
        <v-list>
          <v-list-item-group  v-model="group"
          active-class="orange--text text--accent-4">
          <!--<v-list-item to="register" >Register</v-list-item>-->
          <v-list-item to="/schedule"> 
            <v-list-item-icon><v-icon>mdi-seal</v-icon></v-list-item-icon>
            <v-list-item-content>Standings</v-list-item-content>
          </v-list-item>
          <v-list-item :href="rules.ref">
            <v-list-item-icon><v-icon>mdi-book-cog</v-icon></v-list-item-icon>
            <v-list-item-content>Rules</v-list-item-content>
          </v-list-item>
          <v-list-item to="/classes">
            <v-list-item-icon><v-icon>mdi-tractor-variant</v-icon></v-list-item-icon>
            <v-list-item-content>Classes</v-list-item-content>
          </v-list-item><!--
          <v-list-item to="/schedule" v-if="admin" hidden>
            <v-list-item-icon><v-icon>mdi-clock</v-icon></v-list-item-icon>
            <v-list-item-content>Pull Order & Standings</v-list-item-content>
          </v-list-item>
          -->
          <v-list-item to="/sponsors">
            <v-list-item-icon><v-icon>mdi-trophy-award</v-icon></v-list-item-icon>
            <v-list-item-content>Sponsors</v-list-item-content>
            </v-list-item>
            <v-list-item to="/admin" v-if="admin">
            <v-list-item-icon><v-icon>mdi-trophy-award</v-icon></v-list-item-icon>
            <v-list-item-content>Admin</v-list-item-content>
            </v-list-item>
            <v-list-item> 
              
        <v-img
          alt="Midwest Super Pull Logo"
          class="shrink mx-2"
          active-class="aa"
          contain
          src="../assets/logo_transparent.png"
          transition="scale-transition"
          max-height="150px"
        />
        <!--<div class="shrink mt-1 hidden-md-and-down headline">Midwest Super Pull</div>
        -->
      
    
            </v-list-item>
            
          </v-list-item-group>
        </v-list>
        </v-navigation-drawer>
 
    
    <v-app-bar
      app
      color=""
    >
      <v-btn transparent text to="/" style="margin-left:-25px">
        <v-img
          alt="Midwest Super Pull Logo"
          class="shrink mx-2"
          active-class="aa"
          contain
          src="../assets/logo_transparent.png"
          transition="scale-transition"
          max-height="50px"
          max-width="50px"
        />
        <!--<div class="shrink mt-1 hidden-md-and-down headline">Midwest Super Pull</div>
        -->
      
      </v-btn>
      <v-spacer></v-spacer>
      <!--<v-btn to="/register" text hidden>Register</v-btn>-->
      <v-btn to="/schedule" text class=""> <span class="">Standings</span></v-btn>
      <v-btn :href="rules.ref" _target="_blank" text class=""> <span class="">Rules</span></v-btn>
      <!--<v-btn to="/schedule" text class="d-none d-sm-inline-flex"> <span class="" hidden>Standings</span></v-btn>-->
      <v-btn to="/classes" text link class=" d-none d-sm-inline-flex">Classes</v-btn>
      <v-btn to="/sponsors" text link class=" d-none d-sm-inline-flex mr-5">Sponsors</v-btn>
      <v-btn to="/admin" v-if="admin" text link class=" d-none d-sm-inline-flex">Admin</v-btn>
          <v-btn
           icon
           @click.stop="drawer = !drawer"
           class="d-flex d-sm-none mr-2"
          >
            <v-icon>mdi-menu</v-icon>
          </v-btn>
          
     </v-app-bar>
     </div>
    
</template>

<script>
import {db, auth, rules} from '../../firebaseconfig'
//import {userStatus} from '../store/index.js'
import _ from 'lodash'
 import {
        getAuth,
        signOut
    } from "firebase/auth";

export default {
    name: 'header1',
    data(){
      return {
        rules: {
          ref: "https://firebasestorage.googleapis.com/v0/b/midwestsuperpull.appspot.com/o/General%20Rules%20MSSP%202024.pdf?alt=media&token=63d75660-c530-4cbe-af83-4312a3fa7183"
        },
        group: null,
        drawer:null,
        registration: {},
        userRecord: {},
        openRegistration: false

      }
    },
  
    computed: {
        now: function() {return new Date()},
        
        registerOpen(){
        if (this.now >= '2024-02-01' & this.now > '2024-03-27') {return true}
        else {return false}
      },
      registerOpen1: function() {
        let openDate = new Date('2024-02-01').getTime()
        let now = (this.now).getDate
        return now - openDate
      },
      admin: function() {
        if (sessionStorage.user) {
          if (sessionStorage.user.admin) {
            if (sessionStorage.user.admin == true)
              return true
          }
          else { return false}
        }
        else {return false}
      }
    },

    methods: {
      
    },
    firestore() {
      let u = this.$attrs.signedIn
      return {
        rules: rules,
        registration: db.collection("registration").doc("open")
      }
    }
}
</script>
<style>
body {
  margin: 0 !important;
}

.aa {
  background-color: transparent;
  opacity:0;
}
</style>