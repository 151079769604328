<template>
<div>
    <v-container>
        <v-breadcrumbs>
            <v-breadcrumbs-item to="/classes" color="deep-orange darken-5">Classes</v-breadcrumbs-item>
            <v-breadcrumbs-divider>/</v-breadcrumbs-divider>
            <v-breadcrumbs-item>{{vclass.name}}</v-breadcrumbs-item>
        </v-breadcrumbs>
    <v-row>
        <v-col>
    <v-card flat> 
    <v-img height="150" :src="vclass.img" v-if="vclass.img" class="d-flex align-end">
        <h1 class="text-center justify-center" style="color:white;">{{vclass.name}}</h1>
    </v-img>
    <v-sheet class="mt-5">
        <v-alert prominent type="info" icon="mdi-information-outline">
            Online and mail-in registration is open. Check back later to see more tractors in this class.
        </v-alert>
    </v-sheet>
    
    <v-sheet rounded elevation="1" class="mt-5 px-5" v-if="friday.length >0">
        <v-container-fluid>
            <v-row>
                <v-col>
                    <h2>Friday</h2>
                    <p>Tractors are displayed here in alphabetical order. This does not indicate the pulling order on the day of the event.</p>
                    <v-simple-table>
                         <v-simple-table>
    <template v-slot:default>
      <thead>
        <tr>
          <th class="text-left">
            Name
          </th>
          <th class="text-left">
            Driver
          </th>
          <th class="text-left">
            City
          </th>
          <th class="text-left">
            Make
          </th>
          <th class="text-left">
            Model
          </th>
          <th class="text-left">
            Engine Type
          </th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="tractor in friday"
          :key="tractor.name"
        >
          <td>{{ tractor.name }}</td>
          <td>{{ tractor.details.driver }}</td>
          <td>{{ tractor.details.person.address.city }}</td>
          <td>{{ tractor.details.make }}</td>
          <td>{{ tractor.details.engineBrand }}</td>
          <td>{{ tractor.details.engineType }}</td>
        </tr>
      </tbody>
    </template>
  </v-simple-table>
                    </v-simple-table>
                </v-col>
            </v-row>
        </v-container-fluid>
    </v-sheet>
    <v-sheet rounded elevation="1" class="mt-5 mb-5 px-5" v-if="saturday.length>0">
        <v-container-fluid>
            <v-row>
                <v-col>
                    <h2>Saturday</h2>
                    <p>Tractors are displayed here in alphabetical order. This does not indicate the pulling order on the day of the event.</p>
                    <v-simple-table>
                         <v-simple-table>
    <template v-slot:default>
      <thead>
        <tr>
          <th class="text-left">
            Name
          </th>
          <th class="text-left">
            Driver
          </th>
          <th class="text-left">
            City
          </th>
          <th class="text-left">
            Make
          </th>
          <th class="text-left">
            Model
          </th>
          <th class="text-left">
            Engine Type
          </th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="tractor in saturday"
          :key="tractor.name"
        >
          <td>{{ tractor.name }}</td>
          <td>{{ tractor.details.driver }}</td>
          <td>{{ tractor.details.person.address.city }}</td>
          <td>{{ tractor.details.make }}</td>
          <td>{{ tractor.details.engineBrand }}</td>
          <td>{{ tractor.details.engineType }}</td>
        </tr>
      </tbody>
    </template>
  </v-simple-table>
                    </v-simple-table>

                </v-col>
            </v-row>
        </v-container-fluid>
    </v-sheet>
    </v-card>
        </v-col>
    </v-row>
    <v-row v-if="vclass.sponsors">
      <v-col cols="12">
        <h2 class="text-center">Class Sponsors</h2>
      </v-col>
      <v-card v-for="s in classSponsors" :key="s.id" class="col-md-6 col-sm-6 mx-auto" flat>
            <v-img :src="s.img" contain dark v-if="s.img !=''" style="max-height:100px;"></v-img>
            <v-col v-else class="mx-auto text-center"><v-avatar color="grey lighten-2" size="80" >{{s.avatar}}</v-avatar></v-col>
            <v-list-item>
                <v-list-item-content>
                    <v-list-item-title>{{s.name}}</v-list-item-title>
                    <v-list-item-subtitle>{{s.description}}</v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-avatar size="48"><v-btn text :href="s.website" target="_blank"><v-icon>mdi-open-in-new</v-icon></v-btn></v-list-item-avatar>
            </v-list-item>
        </v-card>
                
            </v-row>
    </v-container>
</div>
</template>
<script>
import {db,sponsors} from "../../firebaseconfig"

export default {
    
    name: 'classDetails',
    data() {
        return {
            vclass: {},
            id: this.$route.params.id,
            friday: [],
            saturday: [],
            now: new Date(),
            cutoff: new Date('2023-04-06'),
            sponsors: []
        }
    },
    firestore() {
        let sp = this.vclass.sponsors
        return {
            vclass: db.collection("classes").doc(this.id),
            friday: db.collection("classes").doc(this.id).collection("2024-04-05").orderBy("name","asc"),
            saturday: db.collection("classes").doc(this.id).collection("2024-04-06").orderBy("name","asc"),
            sponsors: sponsors
          }
    },
    computed: {
      classSponsors() {
        let a = this.vclass
        let b = this.sponsors
        let sp = []

        if (a.sponsors.length > 0) {
          let classSponsors = a.sponsors
          classSponsors.forEach(
            function(value){
              let collector = _.find(b, function(bb){return bb.id == value})
              sp.push(collector)
            }
          )  
            

            }
          
        return sp

      }
    }
}
</script>