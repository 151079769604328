<template>
    <div class="mx-auto">
        <v-row class="mb-3">
            <v-col>
                <h2>Current Year Registrations</h2>
            </v-col>
            
                <v-col cols="12">
                    <v-toolbar elevation="0" rounded color="indigo darken-2" dark>
                    <v-toolbar-title class="mr-5"><div class="">Find User</div></v-toolbar-title>
                        
                    <v-spacer></v-spacer>
                    <v-autocomplete auto-select-first  class="mt-4" append-icon="mdi-magnify" v-model="registerName" :items="filteredRegistration" item-text="[0].fullName" return-object clearable></v-autocomplete>
                    
                    </v-toolbar>
                </v-col>
                <v-col cols="12" v-if="registerName.length == 0"></v-col>
                <v-col cols="12" v-else>
                    <div class=" px-4 mx-4 mb-4" style="border-radius: 15px;">
        <v-row  >
            <v-col >
                <v-btn flat block v-if="registerName[0].paid != true" color="yellow darken-4" dark append  @click="createVehicleEntry(registerName)">
                    <v-icon icon="mdi-alert"></v-icon>&nbsp;
                    Unpaid, Pay  Now</v-btn>
                <v-btn v-else block color="green darken-1" flat outlined>Paid</v-btn>
                <v-simple-table class="mt-3">
                    
                    <tr class="">
                        <td class="overline">Full Name</td>
                        <td>{{registerName[0].fullName}}</td>
                    </tr>
                    <tr class="">
                        <td class="overline">Mailing Address</td>
                        <td>{{registerName[0].mailingAddress}} {{ registerName[0].city }}, {{ registerName[0].state }} {{ registerName[0].zip }}</td>
                    </tr>
                    <tr>
                        <td class="overline">Phone</td>
                        <td>{{registerName[0].phone}}</td>
                    </tr>
                    <tr>
                        <td class="overline">Email</td>
                        <td>{{registerName[0].email}}</td>
                    </tr>
                    
                    <tr v-for="tractor in registerName" :key="tractor.tractor">
                        <td><span class="overline">Tractor</span>
                            <br/><strong>{{ tractor.tractor }}</strong> </td>
                        <td>Friday: {{ tractor.selectedClassesFriday }} <br/>
                            Saturday: {{ tractor.selectedClassesSaturday }}
                        </td>

                    </tr>
                </v-simple-table>
            </v-col>
        

        </v-row>
    </div>

                </v-col>
            <v-col cols="12">
                <v-toolbar elevation="0" rounded color="pink darken-4" dark>
                    <v-toolbar-title class="mr-5"><div class="">Filter ({{ filteredRegistration.length }}) </div></v-toolbar-title>
                    <v-chip-group active-class="indigo-lighten-4--text"  column v-model="filtered" light>
                        <v-chip v-for="s in filters" :key="s.index" filter color="pink darken-3" dark>{{ s }}</v-chip>
                    </v-chip-group>
                
                    <v-toolbar-items>
                        

                    </v-toolbar-items>
                    <v-spacer></v-spacer>
                        <v-btn to="/admin/addRegistration" text append-icon="mdi-magnify" >Add</v-btn>
                </v-toolbar>
            </v-col>
        </v-row>
        <div v-for="f in filteredRegistration" :key="f.index" class="alternatingRow px-4 mx-4 mb-4" style="border-radius: 15px;">
        <v-row  >
        <v-col>
            <small class="overline">Registrant</small><br/>
            {{ f[0].fullName }}<br>
            {{ f[0].email }}

        </v-col>
        <v-col cols="12" sm="4" md="3" lg="3">
            <v-row >
                <v-col>
                <small class="overline">Vehicles</small><br/>
                <div v-for="v in f" :key="v.tractor" style="font-size: 1em; line-height: 0.9em;">
                    <v-row>
                    <v-col cols="1" class="mr-5">
                        <div>
                            <v-menu>
                                <template v-slot:activator="{on, attrs}">
                                <v-btn text color="red" v-bind="attrs" v-on="on"><v-icon>mdi-menu</v-icon></v-btn>
                                </template>
                                <v-list>
                                    <v-list-item @click="openEdit(v)">Edit Classes <v-list-item-action><v-list-item-icon><v-icon>mdi-pencil</v-icon></v-list-item-icon></v-list-item-action></v-list-item>
                                    <v-list-item @click="createSingleEntry(v)">Pay and Create Hooks<v-list-item-action><v-list-item-icon><v-icon>mdi-currency-usd</v-icon></v-list-item-icon></v-list-item-action></v-list-item>
                                    <v-list-item @click="deleteDuplicate(v)">Delete as Duplicate <v-list-item-action><v-list-item-icon><v-icon>mdi-delete</v-icon></v-list-item-icon></v-list-item-action></v-list-item>
                                    <v-list-item style="background-color: green;" v-if="v.paid == true">Paid? {{ v.paid }}</v-list-item>
                                    <v-list-item color="red" v-else style="background-color:red;">Paid? {{ v.paid }}</v-list-item>
                                </v-list>
                            </v-menu>
                        </div>    
                    </v-col>
                    <v-col>
                        <div>{{ v.tractor }} <span v-if="v.paid == true"><v-icon color="green" x-small>mdi-circle</v-icon></span><span v-else><v-icon color="red">mdi-circle</v-icon></span></div>
                    <div style="font-size: 0.5em;"><strong>Friday:</strong> {{ v.selectedClassesFriday }}</div>
                    <div style="font-size: 0.5em;"><strong>Saturday:</strong> {{ v.selectedClassesSaturday }}</div>
                    
                </v-col>
                </v-row>
                </div>
                
            </v-col>
            </v-row>
        </v-col>
        <v-col>
            <small class="overline" style="font-size: 0.3em;">Cost</small><br/>
            💲{{  calculateTotal(f)}}<br>
            <div style="font-size:0.8em;">Digital Invoice? <br/>
                <span>{{ f[0].requestDigitalInvoice }}<br/></span>
                </div>
        </v-col>
        <v-col>
            
        </v-col>
       
        <v-col class="d-flex align-center text-center" cols="12" sm="6" md="2">
            <div v-if="f[0].paid" class="flex">
                <v-btn block outlined color="green" class="flex-grow-1">Paid</v-btn>
            </div>
            <div v-else class="flex">
                Submitted:  {{ f[0].created | formattedDate }}<br/>
                <v-btn block outlined color="red" @click="createVehicleEntry(f)" class="flex-grow-1">Mark as Paid</v-btn>
            </div>
        </v-col>
            
        

        </v-row>
        
    </div>
    <v-dialog v-model="editDialog" persistent scrollable>
            <v-card>
                <v-card-title>{{ edit.tractor }} &nbsp;<v-spacer></v-spacer><v-btn icon @click="closeAndEmpty()"><v-icon>mdi-close</v-icon></v-btn></v-card-title>
                <v-card-text>
                    <v-row>
                        <v-col cols="12">
                            <v-autocomplete label="Friday Classes" :items="friday" v-model="edit.selectedClassesFriday" item-text="name" item-value="id" multiple ></v-autocomplete>
                        </v-col>
                        <v-col cols="12">
                            <v-autocomplete label="Saturday Classes" :items="saturday" v-model="edit.selectedClassesSaturday" item-text="name" item-value="id" multiple></v-autocomplete>
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-card-actions>
                    <v-row>
                        <v-col>
                    <v-btn block @click="closeAndEmpty()" text color="grey darken-1">Cancel</v-btn></v-col>
                    <v-col>
                    <v-btn block class="" outlined color="green darken-4" dark @click="saveWithoutPayment(edit)">Save Changes</v-btn>
                </v-col>
                </v-row>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import {db, friday, saturday} from '../../firebaseconfig'
import _ from 'lodash'
import {doc, updateDoc, deleteDoc} from 'firebase/firestore'


export default {
    name: 'formManagement',
    data(){
        return {
            editDialog: false,
            edit: {},
            forms: [],
            filtered: 0,
            registerName: [],
            filters: ['showAll', 'paid', 'unpaid'],
            formFilter: '',
            currentYear: {
                friday: '2024-04-05',
                saturday: '2024-04-06'
            },
            friday: friday,
            saturday: saturday
        }
    },
    firestore(){
        return {
            forms: db.collection("mailRegistrationForms").where("eventYear","==", 2024)
        }
    },
    computed: {
        registration() {
            let v = Object.values(Object.groupBy(this.forms, (form)=> form.fullName))
            v = _.orderBy(v, function(o){
                return o[0].paid, o[0].created
            })
            return v
        },
        filteredRegistration() {
            let yawn
            if (this.filtered == 0) {yawn = this.registration}
            else if (this.filtered == 1) {
                let a = _.filter(this.registration, function(f){
                    return f[0].paid == true})
                yawn = a
            }
            else if (this.filtered == 2) {
                let a = _.filter(this.registration, function(f){
                    return f[0].paid == false })
                yawn = a
            }
            else {yawn = this.registration}
            return yawn
        }
    },
    filters: {
        formattedDate(timestamp) {
            const date = new Date(timestamp.seconds * 1000)
            const options = {
                weekday: 'long',
                year: 'numeric',
                month: 'long',
                day: 'numeric',
                }
            
            const da = date.toLocaleDateString('en-US', options)
            return da
        }
    },
    methods: {
        async saveWithoutPayment() {
            console.log("Save", this.edit)
            let docRef = doc(db,"mailRegistrationForms",this.edit.id)
            await updateDoc(docRef, this.edit)
            this.edit = {}
            this.editDialog = false

        },
        closeAndEmpty(){
            this.edit = {}
            this.editDialog = false
        },
        async deleteDuplicate(edit) {
            
            if (confirm("Delete this registration for tractor "+edit.tractor+"? This action cannot be undone.") == true) {
                await deleteDoc(doc(db, "mailRegistrationForms", edit.id))
            } else {
                console.log("Deletion canceled!")
            }
        },
        openEdit(originalItem){
            this.edit = originalItem
            console.log('Edit', this.edit)
            this.editDialog = true
        },
        calculateTotal(values) {
            let a =  _.sumBy(values, function(c){
                let cost = c.cost.total
                return cost})
            console.log('calcTotal', a)
            return a
        },
        createVehicleId(vehicleName) {
            let vh = vehicleName.trim()
            vh = vh.replace(/\s/g,'-')
            vh = vh.toLowerCase()
            return vh
        },
        submittedTime(stime) {
            console.log('originalTime', stime)
            let t = new Date(stime)
            console.log(t)
            const options = {
                weekday: 'long', 
                year: 'numeric',
                month: 'long',
                day: "numeric"
            }
            t = stime.toLocaleDateTime(options)
            return t

        },
        async createVehicleEntry(person){

            let vehicles = []
            let friday = '2024-04-05'
            let saturday = '2024-04-06'
            let vehicleArray = _.forEach(person, function(vehicle){
                console.log(vehicle)
                let vehicleName = (vehicle.tractor).trim()
                let personName = vehicle.fullName
                vehicleName = vehicleName.replace(/\s/g,'-')
                vehicleName = vehicleName.toLowerCase()
                console.log(vehicleName)
                let veh = {
                            tractorMake: vehicle.tractorMake,
                            name: vehicle.tractor,
                            engineBrand: vehicle.engineBrand,
                            engineType: vehicle.engineType,
                            make: vehicle.tractorMake,
                            person: {
                                fullName: personName,
                                //age: vehicle.age,
                                //birthdate: vehicle.birthdate,
                                address: {
                                    mailingAddress: vehicle.mailingAddress,
                                    city: vehicle.city,
                                    postalCode: vehicle.postalCode
                                }
                            },
                            driver: vehicle.driver
                        }

                    console.log('veh',veh)
                        
                        let fridayArray = []
                        let saturdayArray = []
                        if ((vehicle.selectedClassesFriday).length > 0) {
                            
                            let fa = _.forEach(vehicle.selectedClassesFriday, function(fridayClass){
                                console.log("vehicleClasseesFriday", fridayClass )
                                let hook = {
                                    classId: fridayClass,
                                    created: new Date(),
                                    date: friday,
                                    details: veh,
                                    hook: Math.floor(Math.random() * 100) + 1,
                                    id: vehicleName,
                                    name: vehicle.tractor
                                }
                                fridayArray.push(hook)
                            })
                        }

                        if ((vehicle.selectedClassesSaturday).length > 0) {
                            let sa = _.forEach(vehicle.selectedClassesSaturday, function(saturdayClass){
                                console.log("vehicleClassesSaturday", saturdayClass )
                                let hook = {
                                    classId: saturdayClass,
                                    created: new Date(),
                                    date: saturday,
                                    details: veh,
                                    hook: Math.floor(Math.random() * 100) + 1,
                                    id: vehicleName,
                                    name: vehicle.tractor
                                }
                                saturdayArray.push(hook)
                            })
                            
                        }

                        console.log('friday', fridayArray)
                        console.log('saturday', saturdayArray)

                        

                let vehRef = db.collection("vehicles").doc(vehicleName)
                vehRef.get().then((doc)=> {
                    if (doc.exists) {
                        console.log("vehicle document", doc)
                        console.log("updates to be made",veh)
                        // Update document
                        //db.collection("vehicles").doc(vehicleName).update(veh)
                       
                        // Define document for class
                    
                    }
                    else {
                        console.log("vehicle doesn't exist!, let's create it!")
                        // Create document
                        db.collection("vehicles").doc(vehicleName).set(veh)
                    }

                     // Define subcollection for date
                     _.forEach(fridayArray, function(upd) {
                            db.collection("vehicles").doc(vehicleName).collection(friday).doc(upd.classId).set(upd)
                            db.collection("classes").doc(upd.classId).collection(friday).doc(upd.id).set(upd)
                        })
                        _.forEach(saturdayArray, function(upd) {
                            db.collection("vehicles").doc(vehicleName).collection(saturday).doc(upd.classId).set(upd)
                            db.collection("classes").doc(upd.classId).collection(saturday).doc(upd.id).set(upd)
                        })

                    db.collection("mailRegistrationForms").doc(vehicle.id).update({"paid": true})
                    
                })
             }
                )
             
        },
        async createSingleEntry(vehicle){

let vehicles = []
let friday = '2024-04-05'
let saturday = '2024-04-06'
    console.log(vehicle)
    let vehicleName = (vehicle.tractor).trim()
    let personName = vehicle.fullName
    vehicleName = vehicleName.replace(/\s/g,'-')
    vehicleName = vehicleName.toLowerCase()
    console.log(vehicleName)
    let veh = {
                tractorMake: vehicle.tractorMake,
                name: vehicle.tractor,
                engineBrand: vehicle.engineBrand,
                engineType: vehicle.engineType,
                make: vehicle.tractorMake,
                person: {
                    fullName: personName,
                    //age: vehicle.age,
                    //birthdate: vehicle.birthdate,
                    address: {
                        mailingAddress: vehicle.mailingAddress,
                        city: vehicle.city,
                        postalCode: vehicle.postalCode
                    }
                },
                driver: vehicle.driver
            }

        console.log('veh',veh)
            
            let fridayArray = []
            let saturdayArray = []
            if ((vehicle.selectedClassesFriday).length > 0) {
                
                let fa = _.forEach(vehicle.selectedClassesFriday, function(fridayClass){
                    console.log("vehicleClasseesFriday", fridayClass )
                    let hook = {
                        classId: fridayClass,
                        created: new Date(),
                        date: friday,
                        details: veh,
                        hook: Math.floor(Math.random() * 100) + 1,
                        id: vehicleName,
                        name: vehicle.tractor
                    }
                    fridayArray.push(hook)
                })
            }

            if ((vehicle.selectedClassesSaturday).length > 0) {
                let sa = _.forEach(vehicle.selectedClassesSaturday, function(saturdayClass){
                    console.log("vehicleClassesSaturday", saturdayClass )
                    let hook = {
                        classId: saturdayClass,
                        created: new Date(),
                        date: saturday,
                        details: veh,
                        hook: Math.floor(Math.random() * 100) + 1,
                        id: vehicleName,
                        name: vehicle.tractor
                    }
                    saturdayArray.push(hook)
                })
                
            }

            console.log('friday', fridayArray)
            console.log('saturday', saturdayArray)

            

    let vehRef = db.collection("vehicles").doc(vehicleName)
    vehRef.get().then((doc)=> {
        if (doc.exists) {
            console.log("vehicle document", doc)
            console.log("updates to be made",veh)
            // Update document
            //db.collection("vehicles").doc(vehicleName).update(veh)
           
            // Define document for class
        
        }
        else {
            console.log("vehicle doesn't exist!, let's create it!")
            // Create document
            db.collection("vehicles").doc(vehicleName).set(veh)
        }

         // Define subcollection for date
         _.forEach(fridayArray, function(upd) {
                db.collection("vehicles").doc(vehicleName).collection(friday).doc(upd.classId).set(upd)
                db.collection("classes").doc(upd.classId).collection(friday).doc(upd.id).set(upd)
            })
            _.forEach(saturdayArray, function(upd) {
                db.collection("vehicles").doc(vehicleName).collection(saturday).doc(upd.classId).set(upd)
                db.collection("classes").doc(upd.classId).collection(saturday).doc(upd.id).set(upd)
            })

        db.collection("mailRegistrationForms").doc(vehicle.id).update({"paid": true})
        
    })
 
    
 
},
        async createHook(hook){
            let friday = this.friday
            let saturday = this.saturday

        },
        deleteRegistrations(registration) {
            console.log("delete registrations")
        }
    }
}
</script>

<style>
.alternatingRow:nth-child(even) {
    background-color: #f6ededdd;
}

.alternatingRow:nth-child(odd) {
    background-color: #fff;
}


</style>