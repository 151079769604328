<template>
    <v-container fluid id="gah">
        <v-row>
            <v-col>
                <v-img max-height="250px" contain src="../assets/logo_inverted.png" class="mr-auto" max-width="250px"></v-img>

               

            </v-col>
             
        </v-row>
        <v-row>
            <v-col>
                     
                    <v-btn text link :href="facebook" max-width="2em">
                        <v-img max-height="2em" contain :src="facebookIcon" alt="facebook logo" max-width="2em"></v-img>
                    </v-btn>
                    <v-btn text link href="mailto:midwestspringsuperpull@gmail.com" max-width="2em">
                        <v-img max-height="2em" contain :src="emailIcon" alt="image of envelope" max-width="2em"></v-img>
                    </v-btn>

               
                       
              
                 </v-col>
        </v-row>
        <v-row>
            <v-col>
            &reg; 2024 Midwest Spring Super Pull
            </v-col>
        </v-row>
    </v-container>

</template>


<script>
    export default {
        name: 'footer1',
        data() {
            return {
                logo: "../assets/logo_inverted.png",
                email: "midwestspringsuperpull@gmail.com",
                emailIcon: "https://firebasestorage.googleapis.com/v0/b/midwestsuperpull.appspot.com/o/email-icon.png?alt=media&token=85e9287a-8aa8-4bec-a8a4-ff2b1e696016",
                facebook: "https://www.facebook.com/MidwestSpringSuperPull",
                facebookIcon: "https://firebasestorage.googleapis.com/v0/b/midwestsuperpull.appspot.com/o/facebook-icon.png?alt=media&token=b09ffb93-3677-4168-b13b-e717ebc2e66a"
            }
        },
        computed: {
            currentYear() {
                const d = new Date();
            },
            mailto() {
                return "mailto:" + this.email
            }
        }
    }
</script>

<style>
    #gah {
        background-color: #000;
        color: #fff;
        padding-top: 2em;
        padding-bottom: 3em;
        width:"100%"
    }
</style>