<template>
  <v-app>
    <span :id="now"></span>
    <header1 :signedIn="user" :admin="admin" class="no-print" :billing="billing" :weighIn="weighIn"></header1>

    <v-main :signedIn="user" :admin="admin" class="main">
      <router-view :admin="admin" :billing="billing" :weighIn="weighIn"/>
    </v-main>
    <footer1 class="no-print"></footer1>
  </v-app>
</template>

<script>
import header1 from './components/header.vue'
import footer1 from './components/footer.vue'
import store from './store/index.js'
import {getAuth, onAuthStateChanged} from 'firebase/auth'
import {db} from '../firebaseconfig'
export default {
  name: 'App',
  components: {
    "header1":header1,
    "footer1":footer1
  },
  data() {
    return {
      user: '',
      userDetails: {},
      admin: false,
      billing: false,
      weighIn: false
    }
  
}, 
computed: {
  
  now() {
    return (new Date()).toDateString()
  }
}

}
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Racing+Sans+One&display=swap');

.application {font-family: 'Open Sans', sans-serif;}

.headline, .title, .subheading,h1,h2,h3 {
  font-family: "Racing Sans One", sans-serif !important;
}

footer1 {
  position: absolute;
  bottom:0;
  width: 100%;
  min-height: 2em;
}

@media print {
    body {
      overflow: auto;
      height: auto;
    }

    .main {
      overflow:auto;
      height:auto;
      position: fixed;
      top: 0;
      width: 100%;
    }

    .pgBreak {
      page-break-after: always;
      margin-top: 50px;
    }

    .no-print {
      display: none !important;
    }

    .pullDetails {
      position: fixed;
      top: 0;
      width: 100%;
    }

  }

</style>