<template>
    <div>
        <v-dialog v-model="show" max-width="500px">
    <template v-slot:activator="{ on, attrs }">
        <v-btn text v-bind="attrs" v-on="on"><v-icon>mdi-pencil</v-icon></v-btn>
    </template>
    <v-card><v-card-title>Edit Class</v-card-title>
    <v-card-subtitle>Use this form to edit vehicle classes selected for this tractor. WARNING: If there have been previous class selections, these choices will overwrite them.</v-card-subtitle>
        <v-card-text>
                        <v-autocomplete v-model="vehicleForm.friday" :items="fridayRef" multiple chips return-object
                            item-text="name" color="secondary"
                            label="Friday - Choose zero or more classes." clearable>
                        </v-autocomplete>
                        <v-autocomplete v-model="vehicleForm.saturday" :items="saturdayRef" multiple chips return-object
                            item-text="name" color="secondary"
                            label="Saturday - Choose zero or more classes." clearable>
                        </v-autocomplete>
        </v-card-text>
        <v-card-actions>
            <v-col><v-btn @click.stop="show = false" text color="primary" block>Cancel</v-btn></v-col>
            <v-col><v-btn @click.stop="addClass()" color="primary" block>Save</v-btn></v-col>
        </v-card-actions>
    </v-card>
</v-dialog>
    </div>
</template>
<script>
    import _ from 'lodash'
    import {
        db, friday, saturday, classes
    } from "../../firebaseconfig"

export default {
    name: 'addClass',
    data() {
        return {
            show: false,
            vehicleForm: {
                friday: '',
                saturday: ''
            },
            classes: classes, 
            friday: friday,
            saturday: saturday,
            success: "",
            error: ""
        }
    },
    firestore() {
        return {
           
        }
    },
    props: {
        user: String,
        vehicle: String
    },
    computed: {
        fridayRef() {
            let f = this.friday
            let col =  []
            _.forEach(f, function(o){
                 col.push({id:o.id, name:o.name,ref: db.collection("classes").doc(o.id)})
            })        
            return col

            },
            saturdayRef() {
                let f = this.saturday
            let col =  []
            _.forEach(f, function(o){
                 col.push({id:o.id, name:o.name,ref: db.collection("classes").doc(o.id)})
            })        
            return col
            }
    },
    methods: {
       addClass: function () {
                let vehicle = this.vehicle
                let myVehicle = db.collection("vehicles").doc(vehicle)
                let newClassesFriday = this.vehicleForm.friday
                let newClassesSaturday = this.vehicleForm.saturday
                let vF = this.vehicleForm

                myVehicle.update({
                    friday: newClassesFriday,
                    saturday: newClassesSaturday
                }).then(function () {
                    console.log("Class added!")
                    vF.friday = {}
                    vF.saturday = {}

                    vF.vehicleName = '',
                    this.show = false

                }).catch(err => {
                    console.log(err)
                })
                /*myVehicle.update({
                    classes: firebase.firestore.FieldValue.arrayUnion(newClasses)
                }).then(function () {
                    console.log("Class added!")
                    vF.vehicleClasses = {}
                    vF.vehicleName = ''
                    vF.addVehicleClass = false
                })*/
            }
    }
}
</script>