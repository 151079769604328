<template>
  <div>
    <hero></hero>
    <mailing></mailing>
    <hotels></hotels>
    <location></location>
    <spotlightsponsors></spotlightsponsors>
   
  </div>
</template>

<script>
  import hero from '../components/hero'
  import location from '../components/location'
  import mailing from '../components/mailing'
  import spotlightsponsors from '../components/spotlightsponsors'
  import hotels from '../components/hotels.vue'
 

  export default {
    name: 'Home',
    components: {
    hero,location,mailing,spotlightsponsors,hotels
    },
  }
        
</script>


