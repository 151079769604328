<template>
    <div>
        <v-dialog v-model="show" width="387">
    <template v-slot:activator="{ on, attrs }">
        <v-btn v-bind="attrs" v-on="on" text>Add Tractor</v-btn>
    </template>
    <v-card><v-card-title>Add Tractor</v-card-title>
    <v-card-text>
        <v-autocomplete v-if="admin" label="Find Orphaned Vehicle" :items="vehicles" v-model="form.selectedVehicle" item-text="name" return-object clearable disabled placeholder="temporarily disabled"></v-autocomplete>
        <div v-if="form.selectedVehicle.userRef" style="margin-top:-15px; font-size:0.9em; color:blue">{{ form.selectedVehicle.userRef.firstName }} {{ form.selectedVehicle.userRef.lastName }} {{ form.selectedVehicle.userRef.email }}</div>
        <div v-if="selectedVehicleKeys === 0">
        <v-text-field v-model="form.name" label="Name"></v-text-field>
        <v-text-field v-model="form.details" label="Details"></v-text-field>
        <v-autocomplete v-model="form.friday" :items="friday" multiple chips return-object
                            item-text="name" :item-value="id" color="secondary"
                            label="Friday - Choose zero or more classes." clearable>
                        </v-autocomplete>
                        <v-autocomplete v-model="form.saturday" :items="saturday" multiple chips return-object
                            item-text="name" :item-value="id" color="secondary"
                            label="Saturday - Choose zero or more classes." clearable>
                        </v-autocomplete>
        </div>
        <div></div>
        
    <v-alert v-if="form.success != ''" type="success">{{form.success}}</v-alert>
    </v-card-text>
    <v-card-actions>
        <v-col>
        <v-btn text @click.stop="close()" block>Close</v-btn>
        </v-col>
        <v-col v-if="selectedVehicleKeys === 0"><v-btn @click="addVehicle" block color="green darken-3" dark>Add</v-btn>
        </v-col>
        <v-col v-else><v-btn @click="addOrphanedVehicle" block color="green darken-3" dark>Associate to this User</v-btn>
        </v-col></v-card-actions>
    </v-card>
</v-dialog>
    </div>
</template>

<script>
   
    import _sumBy from 'lodash/sumBy'
    import _filter from 'lodash/filter'
    import _chain from 'lodash/chain'
    import _ from 'lodash'
    import {
        db, friday, saturday
    } from "../../firebaseconfig"
    

export default {
    name: 'addTractor',
    data() {
        return {
            show: false,
            performingRequest: false,
            vehicles: [],
            friday: friday,
            saturday: saturday,
            form: {
                name: '',
                details: '',
                friday: [],
                saturday: [],
                selectedVehicle: {},
                error: '',
                success:'',
                id: ''
            }
        }
    },
    firestore(){
        return {
        vehicles: db.collection("vehicles").orderBy("name")
        }
    },
    props: {
        classes: Array,
        user: String,
        admin: Boolean
    },
    computed: {
        selectedVehicleKeys(){
            if (this.form.selectedVehicle in [null,undefined,0]) {return 0} else {return Object.keys(this.form.selectedVehicle).length}

        },
    
        /*friday() {
                let a = _filter(this.classes, {
                    friday: true
                }).map(v => 
                    ({
                        id: v.id,
                        name: v.name,
                        ref: db.collection('classes').doc(v.id)
                    }))
                return a
            },
            saturday() {
                let b = _filter(this.classes, {
                    saturday: true
                }).map(v =>

                    ({
                        id: v.id,
                        name: v.name,
                        ref: db.collection('classes').doc(v.id)
                    }))
                return b
            }*/
    },
    methods: {
        addVehicle: function () {
                // start transition/loading view
                let pf = this.performingRequest
                pf = true
                let doc = this.form.name.toLowerCase().split(" ").join("-")
                this.form.error = ''

                // add pull to Firestore
                let vehicleRef = db.collection("vehicles").doc(doc)
                let u = this.user
                let f = this.form
                let sh = this.show
                vehicleRef.get()
                    .then((docSnapshot) => {
                        if (docSnapshot.exists) {
                            console.log("Vehicle already exists!")
                            let vE = "Your vehicle name must be unique. Please give a different name."
                            this.form.error = vE
                        } else {
                            let r = db.collection("users").doc(this.user)
                            db.collection("vehicles").doc(doc).set({
                                name: this.form.name,
                                vehicleDetails: this.form.details,
                                userRef: r,
                                friday: this.form.friday,
                                saturday: this.form.saturday,
                                created: new Date(),
                            }).then(function (docRef) {
                                console.log("Vehicle written with ID: ", doc)
                                let vRf = db.collection("vehicles").doc(doc)
                                f.success = doc+' successfully created!'
                                db.collection("users").doc(u).collection("vehicles").doc(doc).set({
                                    vehicleRef: vRf,
                                    created: new Date()
                                })
                            f.name = ''
                f.friday = []
                f.saturday = []
                f.details = ''
                sh = false    
                                
                            })
                        }
                    })
            },
            addOrphanedVehicle: function () {
                // start transition/loading view
                this.performingRequest = true
                
                let doc = this.form.selectedVehicle.id
                
                let user = db.collection("users").doc(this.user)
                let vehicle = db.collection("vehicles").doc(doc)

                // update vehicle collection
                db.collection("vehicles").doc(doc).update({
                    userRef: user,
                    updated: new Date()
                })
                
                db.collection("users").doc(this.user).collection("vehicles").doc(doc).set({
                        vehicleRef: vehicle,
                        updated: new Date()
                        })              
                        
                this.close()
            },
            close() {
                let f = this.form
                let sh = this.show
                f.selectedVehicle = {}
                f.name = ''
                f.details = ''
                f.friday = []
                f.saturday = []
                f.success = ''
                f.error = ''
                this.show = false
            }
    }
}
</script>