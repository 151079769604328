<template>
    <v-container fluid :data-date="now">
        <v-row>
            <v-col>
                <h1>{{ showYear }} Results</h1>
            </v-col>
        </v-row>
        
    <v-row>
        <v-col>
            <v-btn block elevation="0" to="/schedule">Current Year</v-btn>
        </v-col>

    </v-row>
        
    <!-- 2022 Results-->
        <v-row>
            <v-col>
                <v-card
    class="mx-auto"
  >
    <v-toolbar elevation="0" color="#f2f2f2">
        <v-toolbar-title><h2><v-icon class="mb-1">mdi-timer-sand</v-icon> {{$route.query.year}} Results</h2></v-toolbar-title>
    </v-toolbar>
    <v-card-text style="background-color:#f2f2f2;">
    <v-alert type="info" color="blue-grey">
        These results are for {{ showYear }}. Results for the current year have not yet been updated.
    </v-alert>
        <v-tabs v-model="tab" class="no-print" background-color="transparent" color="blue-grey">
          <v-tabs-slider color="primary"></v-tabs-slider>
          <v-tab href="#Friday2022">Friday</v-tab>
          <v-tab href="#Saturday2022">Saturday</v-tab>
          <v-tab href="#Test" v-if="admin">Test</v-tab>
      </v-tabs>
    <v-tabs-items v-model="tab">
        <v-tab-item value="Friday2022" style="background-color:#f2f2f2;">
            <v-row class="mt-2">
            <v-col><v-text-field label="Day" value="Friday, April 8, 2022" readonly outlined></v-text-field></v-col>
            </v-row>
            <v-expansion-panels multiple>
            <hook-class-day :details="f" :id="f.id" :day="day1" v-for="f in friday" :key="f.index" :admin="admin" style="page-break-after:always" />    
            </v-expansion-panels>    
            
                
               
        
        </v-tab-item>
        <v-tab-item value="Saturday2022">
            <v-row class="mt-2">
            <v-col><v-text-field label="Day" value="Saturday, April 9, 2022" readonly outlined></v-text-field></v-col>
            </v-row>
            <v-expansion-panels multiple>
            <hook-class-day :details="s" :id="s.id" :day="day2" v-for="s in saturday" :key="s.index" :admin="admin" />    
            </v-expansion-panels> 
        </v-tab-item>
        <v-tab-item value="Test" v-if="admin">
            <v-row class="mt-2">
            <v-col><v-text-field label="Day" value="Test" readonly outlined></v-text-field></v-col>
            </v-row>
            <v-expansion-panels>
            <hook-class-day :details="t" :id="t.id" day="test" v-for="t in test" :key="t.index" :admin="admin" />    
            </v-expansion-panels> 
        </v-tab-item>
    </v-tabs-items>
      
        
    </v-card-text>
  </v-card>
            </v-col>
        </v-row>
        
    </v-container>
</template>
<script>
import {db,classes,sponsors} from '../../firebaseconfig'
import _ from 'lodash'
import hookClassDay from '../components/hookClassDay'

export default {
    name: 'previousYearResults',
    data() {
        return {
            showYear: parseInt(this.$route.query.year),
            classes: [],
            caseSensitive:false,
            tab:null,
            now: new Date(),
            sponsors: []
        }
    },
    firestore() {
        return {
            'classes': classes,
            'sponsors': sponsors.where('level','==','platinum')
        }
    },
    computed: {
        day1() {return '2022-04-08'},
        day2() {return '2022-04-09'},
        
        friday() {
            let a = _.filter(this.classes, function(o){
                return o.friday = true && o.fridayOrder 
            })

            let b = _.orderBy(a, function(o) {
                return o.fridayOrder
            })

            return b
        },
        saturday() {
            let a = _.filter(this.classes, function(o){
                return o.saturday = true && o.saturdayOrder 
            })

            let b = _.orderBy(a, function(o) {
                return o.saturdayOrder
            })

            return b
        },
        test() {
            let a = _.filter(this.classes, function(o){
                return o.test = true && o.testOrder 
            })

            let b = _.orderBy(a, function(o) {
                return o.testOrder
            })

            return b
        }
    },
    components: {'hookClassDay':hookClassDay},
    methods: {
        print(day) {
            window.print()
        },
        changeYear(year){
            return this.showYear = year
        },
        displayYear(year){
            this.$router.push({path:'/schedule', query:{year: year}})
        }
    },
    props: {admin:Boolean}
}
</script>
<style>
@media print {
    body {
      overflow: auto;
      height: auto;
    }

    .pgBreak {
      page-break-after: always;
      margin-top: 50px;
    }

    .no-print {
      display: none !important;
    }

    .pullDetails {
      position: fixed;
      top: 0;
      width: 100%;
    }

  }
</style>
