<template>
<div class>
    <v-dialog v-model="dialog" width="500">
        <template v-slot:activator="{ on, attrs }">
            <v-btn color="red" dark v-bind="attrs" v-on="on" fab small elevation="0" icon><v-icon>mdi-delete</v-icon></v-btn>
        </template>
        <v-card>
            <v-card-text>
            <v-card-title>
                Delete {{vehicle.name}}
            </v-card-title>
            
                <v-alert type="error" prominent color="red" dark v-if="success == ''">Are you sure you want to delete {{vehicle.name}}? This cannot be undone.</v-alert>
                <v-alert type="success" prominent v-else>This tractor has been deleted.</v-alert>
                <p v-if="success == ''">Please note that hooks for this tractor (if they have been created) will need to be removed manually.</p>
            <v-card-actions>
                <v-row>
                    <v-col><v-btn text @click.stop="close()" block>Cancel</v-btn></v-col>
                    <v-col><v-btn dark color="red darken-3" @click.stop="deleteTractor(vehicle,user)" block>Delete</v-btn></v-col>
                </v-row>
            </v-card-actions>
            </v-card-text>
            
        </v-card>
    </v-dialog>
</div> 
</template>

<script>
import {db} from '../../firebaseconfig'

export default {
    name: 'deleteTractor',
    data() {
        return {
            dialog: false,
            success: '',
            failure: ''
        }
    },
    props: {vehicle:Object,user:String},
    methods: {
        deleteTractor(tractor,user) {
            let docId = tractor.id
            let uV = db.collection("users").doc(user).collection("vehicles").doc(docId)
            let vV = db.collection("vehicles").doc(docId)
            uV.delete().then(()=> {console.log("User vehicle deleted!")
            
            }).catch((error) => { console.error("Error deleting user vehicle: ", error)}).then(()=> {
                vV.delete(() => {
                    console.log("Vehicle record successfully deleted!",docId)
                    this.success = docId + "successfully deleted"
                }).catch((error)=> {
                    console.error("Error removing document:",error)
                })
            })
        },
        close() {
            this.success = '',
            this.failure = '',
            this.dialog = false
        }
    }
}
</script>