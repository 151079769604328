<template>
    <div>
    <v-card flat outlined>
        <v-card-title class="overline">
            <div>{{userDetails.firstName}} {{userDetails.lastName}}
            <div style="margin-top: -10px;" class="text-caption text-lowercase">            {{ userDetails.email }}
</div>
            </div>
            <v-spacer></v-spacer>
            <span v-if="userDetails.year_2023">
            <v-chip v-if="userDetails.year_2023.paid > 0" small class="mr-5">2023</v-chip>
            <span v-if="userDetails.year_2023.paid >0" style="color:green;">
                <v-icon dense color="green">mdi-currency-usd</v-icon>{{userDetails.year_2023.paid}}&nbsp;
            </span>
        </span>
            <v-icon dense>mdi-tractor-variant</v-icon>{{vehicles1.length}}
            <v-bottom-sheet color="red darken-3" dark scrollable style="min-height: 50vh;">
                <template v-slot:activator="{ on, attrs }">
                    <v-btn v-bind="attrs" v-on="on" dense text style="margin-left:-10px; margin-right:-10px">
                        <v-icon>mdi-dots-vertical</v-icon>
                    </v-btn>
                </template>
                <v-card style="overflow:auto;">
                    <v-list>
                        <v-list-item>

                            <v-list-item-title >
                                <span v-if="userDetails.year_2023">
                                  
                                <v-list-item-subtitle class="overline mb-2" v-if="userDetails.year_2023 && userDetails.year_2023.paid > 0">Paid
                                    {{userDetails.year_2023.paid}}</v-list-item-subtitle>
                                    </span>
                                <v-list-item-subtitle class="overline" v-else style="color:red;">Payment Due
                                </v-list-item-subtitle>
                                <span v-if="userDetails.firstName">
                                {{userDetails.firstName}} {{userDetails.lastName}}
                                </span>
                                <!--<span v-else-if="vehicles1.length > 0 && vehicles1[0].vehicleRef.userRef.firstName">
                                    {{ vehicles1[0].vehicleRef.userRef.firstName }} {{ vehicles1[0].vehicleRef.userRef.lastName }}
                                </span>-->
                            </v-list-item-title>
                            <v-list-item-action>
                                <v-btn @click="deleteUser(userDetails)">
                                    <v-icon>mdi-delete</v-icon>
                                </v-btn>
                            </v-list-item-action>
                        </v-list-item>

<v-list-item>
                            <v-list-item-title>Hooks</v-list-item-title>
                            <v-list-item-action><v-btn @click="hookPosition(userDetails.year_2023.paid,vehicles1,userDetails)"><v-icon>mdi-plus</v-icon></v-btn></v-list-item-action>
                        </v-list-item>
                        <v-list-item>
                            <v-list-item-title>Vehicles</v-list-item-title>
                        </v-list-item>
                        <v-divider></v-divider>
                        <v-expansion-panels>
                            <vehicle v-for="v in vehicles1" :key="v.id" :id="v.id" :details="v" :user="userDetails.id"
                                :title="v.name" />
                        </v-expansion-panels>
                        <v-divider></v-divider>
                        <v-list-item>
                            <v-list-item-avatar>
                                <v-icon>mdi-tractor-variant</v-icon>
                            </v-list-item-avatar>
                            <add-tractor :classes="classes" :user="userDetails.id" :admin="admin" />
                        </v-list-item>
                        <div v-if="userDetails.year_2023">
                        <v-list-item v-if="userDetails.year_2023.paid == 0">
                             <v-list-item-content>
                                 
                                <div v-if="now < new Date(preOrderEnd)"><v-text-field prepend-inner-icon="mdi-currency-usd" label="Preorder Amount Due" :value="totals.pre" v-if="preOrderPricing"></v-text-field></div>
                                <div v-if="now >= new Date(preOrderEnd)">
                            <v-text-field prepend-inner-icon="mdi-currency-usd" label="Full Rate Amount Due" :value="totals.full"></v-text-field></div>
                            
                           
                            
                            
                            </v-list-item-content>
                        </v-list-item>
                    </div>
                        <v-list-item>
                            <v-list-item-avatar>
                                <v-icon>mdi-currency-usd</v-icon>
                            </v-list-item-avatar>
                            <add-payment :user="userDetails.id" :hooks="countHooks" :costs="costs" :preOrderPricing = "preOrderPricing" :prices="prices" :previousPayment="userDetails.year_2023.paid" @paid="hookPosition(userDetails.year_2023.paid,vehicles1,userDetails)"/>
                        </v-list-item>
                    </v-list>
                </v-card>
            </v-bottom-sheet>
        </v-card-title>
        <!--<v-expansion-panels flat>
            <vehicle :id="v.id" :details="v.vehicleRef" v-for="v in vehicles" :key="v.id" :title="v.name" />
        </v-expansion-panels>
        
        <v-card-actions>
            <add-tractor :classes="classes" :user="userDetails.id"/>
            <v-btn>Manage Hooks</v-btn>
            <v-btn>Archive user</v-btn>
        </v-card-actions>-->
    </v-card>
</div>
</template>
<script>
    import {
        db
    } from '../../firebaseconfig'
    import _sumBy from 'lodash/sumBy'
    import _filter from 'lodash/filter'
    import _chain from 'lodash/chain'
    import _ from 'lodash'
    import vehicle from '../components/vehicle'
    import addTractor from '../components/addTractor'
    import addPayment from '../components/addPayment'
    import vehicles from '../components/vehicles'
    import {userVehicles} from '../../firebaseconfig'


    export default {
        name: 'user',
        data() {
            return {
                vehicles1: [],
                sessions: [],
                prices: {
                    preOrder: {
                        reduced: 25,
                        standard: 50
                    },
                    full: {
                        reduced: 35,
                        standard: 60
                    }
                },
                now: new Date(),
                preOrderEnd: '2023-03-22 00:00:00.001'
            }
        },
        props: {
            userDetails: Object,
            classes: Array,
            currentYear: Boolean,
            admin:Boolean,
            //vehicleLookup: Array
        },
        components: {
            'vehicle': vehicle,
            'addTractor': addTractor,
            'addPayment': addPayment,
            'vehicles': vehicles
        },
        firestore() {
            let today = new Date("1/1/2023")
            let userReference = db.collection("users").doc(this.userDetails.id)
            return {
                vehicles1: db.collection("vehicles").where("userRef","==",userReference ),
                sessions: db.collection('users').doc(this.userDetails.id).collection('checkout_sessions').where("timestamp",">=",today),
            }
        },
        computed: {
            
            isCurrentYear(){
                if (this.sessions.length > 0 && this.currentYear == true) {return true}
            },
            preOrderPricing() {
                if (this.now < new Date(this.preOrderEnd)) {return true}
                else {return false}
            },
            costs() {
                return {
                    pre: {
                        reduced: this.prices.preOrder.reduced * this.countHooks.rd_qt,
                        standard: this.prices.preOrder.standard * this.countHooks.st_qt},
                    full: {
                        reduced: this.prices.full.reduced * this.countHooks.rd_qt,
                        standard: this.prices.full.standard * this.countHooks.st_qt}
                }
            },
            totals() {
                return {
                    pre: this.costs.pre.reduced + this.costs.pre.standard,
                    full: this.costs.full.reduced + this.costs.full.standard
                }
            },
            countHooks() {
                let standard
                let reduced
                let v = this.vehicles1

                let m = []

                if (v.length > 0) {
                    _.forEach(v, function (veh) {
                        let a = veh.friday // Friday
                        let b = veh.saturday // Saturday
                        if (a) {
                            _.forEach(a, function (ac) {
                                m.push(ac)
                            })
                        } 
                        if (b) {
                            _.forEach(b, function (bc) {
                                m.push(bc)
                            })
                        } 
                    })
                }

                reduced = _.filter(m, function (r) {
                    return r.reduced === true
                })
                standard = _.filter(m, function (s) {
                    return s.standard === true
                })

                return {
                    hooks: m,
                    count: m.length,
                    standard: standard,
                    st_qt: standard.length,
                    reduced: reduced,
                    rd_qt: reduced.length
                }
            },
            
        },
        methods: {
            deleteUser(user){
                console.log(user.id)
                
                    const response = confirm("Are you sure you want to delete "+user.firstName+" "+user.lastName+"?")
                    if (response) {
                        db.collection("users").doc(user.id).delete().then(()=>{
                            console.log("User successfully deleted!")
                        }).catch((error) =>{console.error("Unable to delete user", error)})
                    } else {
                        console.log("Delete user was cancelled")
                    }
                
            },
            
            hookPosition(s,v,u){
            // create an array to receive new hooks
            let hooks = []
            function randomBetween(min,max) {
                return Math.floor(Math.random()* (max-min+1)+min)
            }
            let now = new Date()
            let start = new Date('2023-04-07')
            let max
            let min
            if (now < start) {
                min = 0
                max = 100
            } else {
                min = 0
                max = 100
            }
            // check if they've added vehicles
            if (v.length > 0)
            {console.log('has added vehicles')}
            // check if they've paid anything
            if (s > 0) {
                console.log(u,'has paid',s)
                _.forEach(v,function(veh){
                    console.log('veh', veh)
                    if (veh.friday) {
                        _.forEach(veh.friday,function(fri){
                            let hook = {
                                id: veh.id,
                                name: veh.name,
                                details: veh.vehicleDetails,
                                classId: fri.id,
                                class: fri.name,
                                user: veh.userRef.id,
                                hook: randomBetween(min,max),
                                date: '2023-04-07',
                                created: now
                            }
                            hooks.push(hook)
                            let fridayRef = db.collection("classes").doc(hook.classId).collection(hook.date).doc(hook.id)
                            let vehicleRef = db.collection("vehicles").doc(hook.id).collection(hook.date).doc(hook.classId)
                            fridayRef.get().then((doc) => {
                                if (doc.exists) {
                                    console.log("friRef hook already exists")
                                } else {
                                    fridayRef.set(hook)
                                    vehicleRef.set(hook)
                                }
                            }).catch((error)=> {
                                console.log('error',error)
                            })
                        })
                    }
                    if (veh.saturday) {
                        _.forEach(veh.saturday,function(sat){
                            let hook = {
                                id: veh.id,
                                name: veh.name,
                                details: veh.vehicleDetails,
                                classId: sat.id,
                                class: sat.name,
                                user: veh.userRef.id,
                                hook: randomBetween(min,max),
                                date: '2023-04-08',
                                created: now
                            }
                            hooks.push(hook)
                            let satRef = db.collection("classes").doc(hook.classId).collection(hook.date).doc(hook.id)
                            let vehicleRef = db.collection("vehicles").doc(hook.id).collection(hook.date).doc(hook.classId)
                            satRef.get().then((doc) => {
                                if (doc.exists) {
                                    console.log("satRef hook already exists")
                                } else {
                                    satRef.set(hook)
                                    vehicleRef.set(hook)
                                }
                            }).catch((error)=> {
                                console.log('error',error)
                            })
                        })
                        
                    }

                    console.log(hooks)
                })
            }
            
            
            

            }

        }
    }
</script>