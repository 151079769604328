<template>
<v-expansion-panel flat focusable>
    <v-expansion-panel-header>{{details.name}} <add-class :vehicle="id" :user="user" /><delete-tractor :vehicle="details" :user="user" /></v-expansion-panel-header>
    <v-expansion-panel-content>
        <p>{{details.vehicleDetails}}</p>
        <v-simple-table>
            <tr v-if="details.friday.length > 0">
                <td>Friday</td>
                <td><div v-for="f in details.friday" :key="f.id"><v-chip close close-icon="mdi-delete" @click:close="removeClass(details,'friday',f)" disabled>{{f.name}}</v-chip><br/></div></td>
            </tr>
            <tr v-if="details.saturday.length > 0">
                <td>Saturday</td>
                <td><div v-for="s in details.saturday" :key="s.id"><v-chip close close-icon="mdi-delete" @click:close="removeClass(details,'saturday',s)" disabled>{{s.name}}</v-chip><br/></div></td>
            </tr>
            <tr>
                <td>Passed Tech?</td>
                <td><v-checkbox v-model="passedTech" @click.stop="updateTech(id,passedTech)"></v-checkbox></td>
            </tr>
        </v-simple-table>
    </v-expansion-panel-content>
</v-expansion-panel>
</template>

<script>
import {db,auth} from '../../firebaseconfig'
import addClass from '../components/addClass'
import deleteTractor from '../components/deleteTractor'
export default {
    name: 'vehicle',
    props: {details:Object, id:String,user:String},
    components: {'addClass':addClass, 'deleteTractor':deleteTractor},
    data(){
        return {
            passedTech: null,
        }
    },
    firestore(){
        let today = new Date()
        let currentTech = today.getFullYear()
        return {
            passedTech: db.collection("vehicles").doc(this.id).collection("tech").doc(currentTech)
        }
    },
    methods: {
        updateTech(v,passedTech){
            let techCollect = db.collection("vehicles").doc(this.id).collection("tech")
            let today = new Date()
            let year = today.getFullYear()
            let techName 
            let vehicle = this.id
            let user = auth.currentUser
            if (user) {techAdminUID = user.uid}
                techName = {   
                    id: user.uid,
                    email: user.email
                }

            const techDetails = {
                year: year,
                vehicleId: vehicle,
                passedTech: passedTech,
                techName: techName
            }

            year = year.toString()
            techCollect.doc(year).set(techDetails).then(
                () => {console.log("Tech successfully written")}
            )
        },
        removeClass(v,day,f) {
            let vehicle = v.id
            let instring = v+'.'+day
            let index = instring.indexOf(f)
            console.log(index)
            let myVehicle = db.collection("vehicles").doc(vehicle)
            let userVehicle = db.collection('users').doc(this.user).collection('vehicles').doc(vehicle)
            console.log("attempted to remove")
            myVehicle.update({
                classes: db.FieldValue.arrayRemove(c)
            })
            userVehicle.update({
                classes: db.FieldValue.arrayRemove(c)
            })
        }
    }
}
</script>