import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import VueAnalytics from 'vue-analytics';
import vuetify from './plugins/vuetify'
import header from './components/header'
import footer from './components/footer'
import {firestorePlugin} from 'vuefire'
import _sumBy from 'lodash/sumBy'
import VueHtmlToPaper from "vue-html-to-paper";


Vue.component('footer1',footer)
Vue.component('header1',header)



Vue.use(firestorePlugin)
Vue.use(_sumBy)

Vue.config.productionTip = false

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App),
  watch: {
    '$route' (to, from) {
      document.title = to.meta.title || 'MSSP'
    }
  }
}).$mount('#app')

Vue.use(VueAnalytics, {
  id: 'G-HPD4F8CQL8',
  router
})

const options = {
  name: "_blank",
  specs: ["fullscreen=yes", "titlebar=yes", "scrollbars=yes"],
  styles: [
    "https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css",
    "https://unpkg.com/kidlat-css/css/kidlat.css",
    '@/assets/css/styles.css'
  ]
};

Vue.use(VueHtmlToPaper, options);
