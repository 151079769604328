<template>
    <div>
        <v-dialog v-model="show" width="387">
    <template v-slot:activator="{ on, attrs }">
        <v-btn text v-bind="attrs" v-on="on">Vehicles</v-btn>
    </template>
    <v-card><v-card-title>Vehicles</v-card-title>
    <v-card-text>
        <div v-for="v in vehicles" :key="v.id">
            {{v.vehicleRef.name}}<br>

                <v-chip v-for="f in v.vehicleRef.friday" :key="f.id">{{f.name}}<v-btn x-small color="purple" text><v-icon dense large>mdi-alpha-f-circle</v-icon></v-btn></v-chip>
                <v-chip v-for="s in v.vehicleRef.saturday" :key="s.id">{{s.name}}<v-btn x-small color="blue" text><v-icon dense large>mdi-alpha-s-circle</v-icon></v-btn></v-chip>
            
        </div>
    </v-card-text>
    <v-card-actions>
        <v-col>
        <v-btn text @click="show = false" block>Cancel</v-btn>
        </v-col><v-col><add-tractor :user="user" :classes="classes"/>
        </v-col></v-card-actions>
    </v-card>
</v-dialog>
    </div>
</template>
<script>
    import _ from 'lodash'
    import {
        db
    } from "../../firebaseconfig"
    import vehicle from '../components/vehicle'
    import addTractor from '../components/addTractor'
export default {
    name: 'vehicles',
    data() {
        return {
            show: false
        }
    },
    props: {
        classes: Array,
        user: String,
        vehicles: Array
    },
    components: {
        'vehicle': vehicle,
        'addTractor':addTractor
    },
    computed: {
    },
    methods: {
       
    }
}
</script>