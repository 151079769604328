<template>
    <div>
        <v-container>
        <v-row>
            <v-col>
        <h1>{{userDetails.firstName}}'s Account</h1>
        </v-col>
        </v-row>
        <v-row>
            <v-col cols="12">
                <v-card flat>
                    <v-row>
                    <v-col cols="6">
                        <v-text-field label="First Name" :value="userDetails.firstName" readonly filled color="deep-orange darken-5"></v-text-field>
                    </v-col>
                    <v-col cols="6">
                        <v-text-field label="Last Name" :value="userDetails.lastName" readonly filled color="deep-orange darken-5"></v-text-field>
                    </v-col>
                    </v-row>
                    <v-row>
                    <v-col cols="12" style="margin-top:-40px">
                        <v-text-field label="Email" :value="userDetails.email" readonly filled color="deep-orange darken-5"></v-text-field>
                    </v-col>
                    </v-row>
                </v-card>
            </v-col>
        </v-row>
        </v-container>
         <v-container>
        <v-toolbar elevation="0">
            <v-toolbar-title>Vehicles</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-toolbar-items>
                <v-btn text color="deep-orange darken-3" @click.stop="vehicleForm.addVehicleForm = true" >
                    Add Vehicle<v-icon>mdi-star-plus-outline</v-icon>
                </v-btn>
                
            </v-toolbar-items>
        </v-toolbar>
        
       
           
            <v-dialog v-model="vehicleForm.addVehicleClass" max-width="600px">
                <v-card>
                    <v-card-title>Add classes to {{vehicleForm.name}}</v-card-title>
                    <v-card-subtitle>All pre-order hooks cost $50 except for those in the 1050 stock - 14 hp Youth and 950 - Stock classes, which cost $25.</v-card-subtitle>
                    <v-card-text>
                        <v-autocomplete v-model="vehicleForm.friday" :items="friday" multiple chips return-object
                            item-text="name" :item-value="id" color="secondary"
                            label="Friday - Choose zero or more classes." clearable>
                        </v-autocomplete>
                        <v-autocomplete v-model="vehicleForm.saturday" :items="saturday" multiple chips return-object
                            item-text="name" :item-value="id" color="secondary"
                            label="Saturday - Choose zero or more classes." clearable>
                        </v-autocomplete>
                    </v-card-text>
                    <v-card-actions>
                        <v-btn @click.stop="addClass()">Save</v-btn>
                        <v-btn @click.stop="vehicleForm.addVehicleClass = false">Cancel</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
           
            <v-dialog v-model="vehicleForm.addVehicleForm" max-width="600px">
                <v-card>
                    <v-card-title>Add vehicle</v-card-title>
                    <v-card-subtitle>All pre-order hooks cost $50 except for those in the 1050 stock - 14 hp Youth and 950 - Stock classes, which cost $25.</v-card-subtitle>
                    <v-card-text>
                        <v-text-field label="Vehicle Name" v-model="vehicleForm.name" clearable
                            :error-messages="vehicleForm.vehicleError">
                        </v-text-field>
                        <v-text-field v-model="vehicleForm.vehicleDetails" label="Details"
                            hint="Tell us about your tractor, its story, etc."></v-text-field>

                        <v-autocomplete v-model="vehicleForm.friday" :items="friday" multiple chips return-object
                            item-text="name" item-value="name" color="secondary"
                            label="Friday - Choose a vehicle class (or classes)." clearable>
                        </v-autocomplete>
                        <v-autocomplete v-model="vehicleForm.saturday" :items="saturday" multiple chips return-object
                            item-text="name" item-value="name" color="secondary"
                            label="Saturday - Choose a vehicle class (or classes)." clearable>
                        </v-autocomplete>
                    </v-card-text>
                    <v-card-actions>
                        <v-col>
                        <v-btn text @click.stop="close()" block>Close</v-btn>
                        </v-col>
                        <v-col>
                        <v-btn block depressed color="green darken-3" dark @click="addVehicle">Add</v-btn>
                        </v-col>
                    </v-card-actions>
                </v-card>
            </v-dialog>

        <v-alert prominent type="info" color="blue-grey" v-if="vehicles.length==0">Click "Add Vehicle" to configure your vehicle and vehicle classes for the event. This information is used to calculate the number of hooks, as well as register your vehicles.</v-alert>
        <div v-else>
            <v-row>
                <v-col>
                <v-card>
                    <v-simple-table>
                        <template v-slot:default>
                        <thead>
                            <tr>
                                <th class="text-left">
                                Vehicle
                            </th> 
                            <th class="text-left">
                                Days & Classes
                            </th>
                            <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="v in vehicles" :key="v.index" style="margin-top:-20px">
                                <td>
                                    <div class="text-h6">{{v.vehicleRef.name}}</div>
                                    {{v.vehicleRef.vehicleDetails}}
                                </td>
                            <td>
                                <div class="mb-5">
                                Friday
                                <v-chip v-for="fri in v.vehicleRef.friday"
                                    :key="fri.name" dense light color="gray">
                                    {{fri.name}} <span v-if="fri.reduced">- $25</span> <span v-else>- $50</span></v-chip>
                                </div>
                                <div>
                                Saturday 
                                <v-chip v-for="sat in v.vehicleRef.saturday"
                                    :key="sat.name" dense light color="gray">
                                    {{sat.name}} 
                                    </v-chip>
                                </div>
                                </td>
                            <td>
                                <v-row class="d-flex">
                                    <v-btn class="ml-3 mr-2" light color="gray ligten-2" elevation="0"
                                    @click.stop="addClassDialog(v)" fab small>
                                    <v-icon>mdi-pencil</v-icon>
                                </v-btn>
                                <delete-tractor :vehicle="v" :user="user" />
                                </v-row>
                                
                            </td>
                            
                                            </tr>
                        </tbody>
                    </template>

                    </v-simple-table>
                   
                    <v-card-actions>
                        <v-row>
                        <v-col cols="12">
                        
                        </v-col>
                        </v-row>
                    </v-card-actions>
                </v-card>
                </v-col>
                </v-row>
    
            

        </div>

        </v-container>
        <!--
        <v-container v-if="paidCurrent">
            <v-row>
                <v-col>
                    <v-card class="d-flex" hidden>
                        <v-col cols="12" md="4"><v-img :src="qrCodeSrc" max-width="500px" min-width="200px" hidden></v-img></v-col>
                        <v-col cols="12" md="4">
                            <v-card-title>Quick Pass</v-card-title>
                            <v-card-text>
                            <p>Use this QR code to speed up your check-in and tech experiences.</p>
                        </v-card-text></v-col>
                        <v-col>
                            <div class="text-h5 mt-5">Admin Functions</div>
                            <v-btn block class="px-5 mt-5">Tech</v-btn>
                            <v-btn block class="px-5 mt-5">Weight</v-btn>
                        </v-col>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>-->
        
        <v-container>
            <v-toolbar flat class="mt-5">
                    <v-toolbar-title><v-icon>mdi-ticket</v-icon> Tickets</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-toolbar-items>
                        <v-btn text v-if="!hide">
                            <v-icon color="red">mdi-alert-octagon</v-icon> Incomplete
                        </v-btn>
                        
                        <v-btn text v-else>
                            <v-icon color="green">mdi-check-outline</v-icon> Paid
                        </v-btn>
                    </v-toolbar-items>
                </v-toolbar>
        </v-container>
        
        <v-container v-if="registration.open">
            <v-row>
            <v-card>
                <v-row>
                <v-col cols="12" sm="9" md="8">
                <v-card-title>
                    Hook Fee
                </v-card-title>
                <v-card-subtitle>
                    Tractors pay a flat fee to attach to the sled. Standard hooks cost $50 when bought online, and youth/reduced hooks cost $25. Each hook raises in price by $10 if registering on the day of the event.<strong> Each tractor gets two pit passes for the whole weekend.</strong> Children 10 and under are free.
                </v-card-subtitle>
            </v-col>
            
                <v-col class=" text-center d-flex" cols="12" sm="4" md="3">
                    <div class="align-self-center mx-auto">            
                    <v-text-field
                                filled
                                prepend-icon="mdi-minus-thick"
                                :value="countHooks.rd_qt+countHooks.st_qt"
                                append-outer-icon="mdi-plus-thick"
                                
                                readonly
                                hide-details
                                class="centered-input"
                                >
                                    
                                </v-text-field>
                                <div class="text-caption">Standard Rate: {{countHooks.st_qt}} x $50</div>
                                <div class="text-caption">Reduced Rate: {{countHooks.rd_qt}} x $25</div></div>

                            </v-col>
            
            </v-row>
            <v-card-actions v-if="currentlyBilled">
            <v-chip-group column>
                <v-chip color="green" dark v-if="currentlyBilled.types.standard.count > 0" label>Purchased {{ currentlyBilled.types.standard.count }} Standard Hook(s)</v-chip><v-chip color="green" dark v-if="currentlyBilled.types.reduced.count > 0" label>Purchased {{ currentlyBilled.types.reduced.count }} Reduced Hook(s)</v-chip>
            </v-chip-group>
            </v-card-actions>
            </v-card>
            </v-row>
            <v-row class="mt-5" v>
                <v-card>
                <v-row>
                <v-col cols="12" sm="9" md="8">
                <v-card-title>
                    Pit Pass
                </v-card-title>
                <v-card-subtitle>
                    Crews supporting a vehicle or wishing to visit the pit need a pit pass. ** Each tractor gets two pit passes.
                    Pit passes cost $15/each for the entire weekend.
                </v-card-subtitle>
            </v-col>
            
                <v-col class=" text-center d-flex" cols="12" sm="4" md="3">
                    <div class="align-self-center text-center mx-auto">
                        
                                <v-text-field
                                prepend-icon="mdi-minus-thick"
                                @click:prepend="pitPass--"
                                @click:append-outer="pitPass++"
                                filled
                                :value="pitPass"
                                append-outer-icon="mdi-plus-thick"
                                hide-details
                                class="centered-input align-self-center"
                                >
                                    
                                </v-text-field>
                                <div class="text-caption">Weekend Rate: {{pitPass}} x $15</div>
                            </div>
                            
                            </v-col>
            
            </v-row>
            <v-card-actions v-if="currentlyBilled">
                <v-chip color="green" dark v-if="currentlyBilled.types.pit.count > 0" label>Purchased {{ currentlyBilled.types.pit.count }} Pit Pass(es)</v-chip>
            </v-card-actions>
            </v-card>
            </v-row>
            <v-row class="mt-5">
                <v-card>
                <v-row>
                <v-col cols="12" sm="9" md="8">
                <v-card-title>
                    Spectator Pass
                </v-card-title>
                <v-card-subtitle>
                    Spectators are welcome! Buy a spectator pass to attend the event, but not access the pit. Spectator passes are sold at $5/day. 
                </v-card-subtitle>
            </v-col>
            
                <v-col class=" text-center d-flex" cols="12" sm="4" md="3">
                    <div class="align-self-center mx-auto">
                                <v-text-field
                                prepend-icon="mdi-minus-thick"
                                @click:prepend="spectatorPass--"
                                @click:append-outer="spectatorPass++"
                                filled
                                :value="spectatorPass"
                                append-outer-icon="mdi-plus-thick"
                                hide-details
                                class="centered-input"
                                >
                                    
                                </v-text-field>
                                <div class="text-caption">Day Rate: {{spectatorPass}} x $5</div>
                            </div>
                            </v-col>
            
            </v-row>
            <v-card-actions v-if="currentlyBilled">
                <v-chip color="green" dark v-if="currentlyBilled.types.spectator.count > 0" label>Purchased {{ currentlyBilled.types.spectator.count }} Spectator Pass(es)</v-chip>
            </v-card-actions>
            </v-card>
            </v-row>
            <v-row>
                <v-col cols="12">
                    <v-btn block color="success" @click="addToCart(countHooks,pitPass,spectatorPass)">Add to Cart</v-btn>
                </v-col>
            </v-row>
        </v-container>

       
        <!--<v-container>
            <v-toolbar flat class="mt-5">
                    <v-toolbar-title><v-icon>mdi-bookmark</v-icon> Vehicle Results</v-toolbar-title>
                    <v-spacer></v-spacer>
                   
                </v-toolbar>
                <div v-for="v in userVehicleHistory" :key="v.index">
                    <div>{{ v.name }}</div>
                    <v-simple-table>
                        
                    </v-simple-table>

                </div>
        </v-container>-->
        <v-container v-if="currentlyBilled.sessions != []">
            <v-toolbar flat class="mt-5">
                    <v-toolbar-title><v-icon>mdi-history</v-icon> Purchase History</v-toolbar-title>
                    <v-spacer></v-spacer>
                    
                </v-toolbar>
                <v-simple-table>
                    <template v-slot:default>
                                <thead>
                                    <tr>
                                    <th class="text-left">
                                        Timestamp
                                    </th>
                                    <th class="text-left">
                                        Mode
                                    </th>
                                    <th>
                                        Outcome
                                    </th>
                                    
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr
                                    v-for="item in currentlyBilled.sessions"
                                    :key="item.index"
                                    >
                                    <td>{{(item.timestamp).toDate()}}</td>
                                    <td>{{item.mode}}</td>
                                    <td v-if="item.mode == 'payment'">{{ item.paid }}</td>
                                    </tr>
                                </tbody>
                            </template>
                </v-simple-table>
        </v-container>
        
                                
        <!--                         
                        <v-col>
                                     <stripe-checkout ref="checkoutRef" :pk="stripe.publishableKey" mode="payment" :line-items="lineItems" :success-url="successURL" :cancel-url="cancelURL" @loading="v => stripe.loading = v" :customerEmail="userDetails.email" :clientId="user" :items="hooks"/>
                            <v-btn @click="submit" dark color="green darken-5" :loading="stripeloading" v-if="!hide" block>Pay now!</v-btn>
                        
                        </v-col> 

                        -->
                        
            

        

    <div style="position: fixed; bottom: 15px; right: 15px;">
    <v-badge :content="cart.length" :value="cart" color="green" left overlap>
        <v-btn fab large color="green" dark @click.stop="showCart = true">
            <v-icon>mdi-cart</v-icon>
        </v-btn>
    </v-badge>
    </div>
    <v-dialog v-model="showCart" scrollable>
        <v-card>
        
            <v-card-title>Review Cart
                <v-spacer></v-spacer>
                <v-btn icon @click="showCart = !showCart" ><v-icon>mdi-close</v-icon></v-btn>
            </v-card-title>
            <v-card-subtitle>Check items in your cart before continuing to payment.</v-card-subtitle>
            <v-card-text>
                <div>
                    <v-row>
                        <v-col cols="12">
                        <v-simple-table v-if="cart.length > 0">
                            <template v-slot:default>
                                <thead>
                                    <tr>
                                    <th class="text-left">
                                        Summary
                                    </th>
                                    <th class="text-left">
                                        Quantity
                                    </th>
                                    
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr
                                    v-for="item in cart"
                                    :key="item.index"
                                    >
                                    <td>{{item.name}}</td>
                                    <td>{{item.quantity}}</td>
                                    </tr>
                                </tbody>
                            </template>
                            
                        </v-simple-table>
                        <v-alert v-else>
                            You have no items in your cart. 
                        </v-alert>
                    </v-col>
                    
                    </v-row>
                </div>
            </v-card-text>
            <v-card-actions>
                <v-row>
                    <v-col cols="12" sm="6" md="6">
                        <stripe-checkout ref="checkoutRef" :pk="stripeKey" mode="payment" :line-items="stripeLI" :success-url="successURL" :cancel-url="cancelURL" @loading="v => stripe.loading = v" :customerEmail="userDetails.email" :clientId="user" :items="hooks"/>
    
                        <v-btn block @click="submit" color="green" dark>
                            <v-icon left>mdi-cart</v-icon>
                            Continue to Checkout</v-btn>
                    </v-col>
                    <v-col cols="12" sm="6" md="6">
                        <v-btn @click="cart = []" block text class="mb-5">Empty Cart</v-btn>
                    </v-col>
                </v-row>
            </v-card-actions>
        </v-card>
    </v-dialog>
    
    </div>

</template>

<script>
    import _sumBy from 'lodash/sumBy'
    import _filter from 'lodash/filter'
    import _chain from 'lodash/chain'
    import _ from 'lodash'
    import {
        db,classes
    } from "../../firebaseconfig"
    import {
        getAuth,
        onAuthStateChanged
    } from "firebase/auth";
    import {
        StripeCheckout
    } from '@vue-stripe/vue-stripe'
    import deleteTractor from '../components/deleteTractor.vue'
    import {functions} from '../../firebaseconfig'
    import { httpsCallable } from 'firebase/functions'
    import {userStatus} from '../store/index.js'


    //import { getStripeProducts } from '../../functions'

    export default {
        name: 'account',
        
        data() {
            
      
            return {
                cart: [],
                showCart: false,
                user: this.$route.params.id,
                stripeloading: false,
                userDetails: {},
                vehicles: [],
                //form: '',
                registration: '',
                pitPass: 0,
                pitPassType: 'weekend',
                spectatorPass: 0,
                spectatorPassType: 'daily',
                vehicleForm: {
                    id: '',
                    name: '',
                    vehicleDetails: '',
                    friday: [],
                    saturday: [],
                    vehicleError: '',
                    addVehicleForm: false,
                    addVehicleClass: false,
                    success:''
                },
                historyHeaders: [
          {
            text: 'Time',
            align: 'start',
            sortable: true,
            value: 'timestamp',
          },
          { text: 'Hooks', value: 'hooks' },
          { text: 'Paid', value: 'paid' }
        ],
                products: [
                    {
                        name: 'Hook Fee',
                        id:'hookFee',
                        description: "Tractors pay a flat fee to attach to the sled. Hook fees are discounted for early registration and youth classes. Each tractor gets two competition passes for the whole weekend. ",
                        dev: {
                            id: '',
                            value: {
                                    reduced: 25,
                                    standard: 50
                                }
                            
                        },
                        prod: {
                            id: '',
                            value: {
                                reduced: 25,
                                full: 50
                            }
                        }
                    },
                    {
                        name: 'Pit Pass',
                        id: 'pitPass',
                        description:"Crews supporting a vehicle or wishing to visit the pit need a pit pass. ** Each tractor gets two pit passes.",
                        dev: {
                            single: 15,
                            full: 20
                        },
                        prod: {
                            single: 15,
                            full: 20
                        }
                    },
                    {
                        name: 'Spectator Pass',
                        id: 'spectatorPass',
                        description: "Spectators are welcome! Buy a spectator pass to attend the event, but not access the pit.",
                        dev: {
                            id: '',
                            value: ''
                        },
                        prod: {
                            id: '',
                            value: ''
                        }
                    }
                ],
                stripe: {},
                userSessions: [],
                ckSession: 0,
                vehicleClasses: [],
                userVehicleHistory: [],
                april20220408: [],
                domain: window.location.host,
                stripeProducts: [],
                addHooksToCart: true,
                addPitToCart: true,
                addSpecToCart: true,
                prices: []
            }
        },
        //props: {form:String},
        firestore() {
            let ur = this.$route.params.id
            let uRef = db.collection("users").doc(ur)
            let currentYear = new Date("January 1, 2024")

            return {
                vehicleClasses: classes,
                prices: db.collection("price"),
                vehicles: db.collection("users").doc(ur).collection("vehicles"),
                userVehicleHistory: db.collection("vehicles").where("userRef","==",uRef),
                friday: db.collectionGroup("2023-04-07").where("userRef","==",uRef),
                saturday: db.collectionGroup("2023-04-08").where("userRef","==",uRef),
                userDetails: db.collection("users").doc(this.$route.params.id),
                userSessions: db.collection("users").doc(ur).collection("checkout_sessions").where("timestamp",">=",currentYear).orderBy("timestamp","desc"),
                stripe: db.collection("applications").doc("stripe"),
                //form: db.collection("rules").doc("2023"),
                registration: db.collection("registration").doc("open")
            }
        },
        watch: {
            userDetails(newValue) {console.log("userDetails"+newValue.firstName)},
            vehicles(newValue) {console.log("vehicles",newValue)},
            userSessions(newValue) {console.log("userSessions",newValue)}
        },
        created() {
            const auth = getAuth()
            onAuthStateChanged(auth, (user) => {
                if (user) {
                    const uid = user.uid
                    userStatus.uid = user.uid
                    return uid
                } else {
                    // User is signed out
                }
            })
        },
        computed: {
            stripeKey(){
                
                let host = window.location.host
                if (host != "midwestsuperpull.com") {return this.stripe.publishable_key_dev }
                else {return this.stripe.publishableKey}
      
            },
            currentlyBilled(){
                let uS = this.userSessions
                uS = _.filter(uS, function (a){return a.paid})
                let spectatorPrice = ["price_1MWXYuHCwTRZaEaBSiWX11wl","price_1MWwAaHCwTRZaEaBxPPV2bP7"]
                let pitPassPrice = ["price_1MWXVcHCwTRZaEaBMnkKsg7P","price_1MWXVbHCwTRZaEaBQ4Me9wzj","price_1MT5KZHCwTRZaEaB7PKYJPYz","price_1MIJNzHCwTRZaEaBC4qrPDC5"]
                let reducedPrice = ["price_1MWXPgHCwTRZaEaBGq3UCNge","price_1MWXPgHCwTRZaEaBGq3UCNge", "price_1MT4MWHCwTRZaEaBYLvV61Z4" ]
                let standardPrice = ["price_1MWXTlHCwTRZaEaBoSDfPSmR","price_1MPytoHCwTRZaEaBCBkTMYFp", "price_1MWq2gHCwTRZaEaBsH7Zs1r9","price_1MSjUyHCwTRZaEaBMwROq5vM"]
                let lineItems = [] 
                _.forEach((uS), function(a){
                    _.forEach(a.lineItems, function(b){lineItems.push(b)})
                })

                let prices = _.groupBy(lineItems, function(price){return price.price})
                let spectator = []
                let pit = []
                let standard = []
                let reduced = []
                _.forEach(prices, function(o){
                    if (spectatorPrice.includes(o[0].price)) {spectator.push(o)}
                    else if (pitPassPrice.includes(o[0].price)) {pit.push(o)}
                    else if (reducedPrice.includes(o[0].price)) {reduced.push(o)}
                    else if (standardPrice.includes(o[0].price)) {standard.push(o)}
                    else {console.log("something is missing in the price configs!")}
                })

                let summary = {
                    sessions: uS,
                    paid: _.sumBy(uS, function(a){return a.paid}),
                    prices: prices,
                    types: {
                        spectator: {details:spectator[0], count: _.sumBy(spectator[0],'quantity') },
                        pit: {details:pit[0], count: _.sumBy(pit[0],'quantity') },
                        standard:{details:standard[0], count: _.sumBy(standard[0],'quantity') },
                        reduced:{details:reduced[0], count: _.sumBy(reduced[0],'quantity') }
                    }
                    
                }
                return summary
                
            },
            userState() {
                userStatus.admin = this.userDetails.admin
                userStatus.billing = this.userDetails.billing
                userStatus.weighIn = this.userDetails.weighIn
                userStatus.userDetails = this.userDetails
                return "updated"
            },
            now() {
                return new Date()
            },
            defaultPitPass() {
                if (this.vehicles.length > 0) {
                    return this.vehicles.length * 2
                } else {return 0}
            },
            paidCurrent(){
                let lastUserSession = this.userSessions[0]
                let lastSessionDate = lastUserSession.timestamp.toDate()
                let lastSessionYear = lastSessionDate.getFullYear()
                let currentDate = new Date()
                let currentYear = currentDate.getFullYear()
                if (currentYear != lastSessionYear) {return false}
                else {return true}
            },
            qrCodeSrc() {
                let uri = encodeURI(document.URL)
                let chart = "https://chart.googleapis.com/chart?cht=qr&chs=500x500&chl="+uri
                return chart},
            pitPassCost(){
                if (this.pitPass > 0 && this.pitPassType == 'single') {
                    return this.pitPass * 10}
                else if (this.pitPass > 0 && this.pitPassType == 'weekend') {
                    if (this.vehicles.length > 0) {
                        let pp = this.pitPass - (this.vehicles.length *2)
                        return pp * 10
                    }
                    else {return this.pitPass * 10}
                } else { return 0}
            },
            spectatorPassCost(){
                if (this.spectatorPass > 0 && this.spectatorPassType == 'single') {
                    return this.spectatorPass * 5}
                else if (this.spectatorPass > 0 && this.spectatorPassType == 'weekend') {
                    return this.spectatorPass * 5
                } else { return 0}
            },
            stripeLI(){
                const cart = this.cart
                return cart.map(({name, subtotal, ...keepAttrs}) => keepAttrs)

            },
            showInvoice() {
                if (this.vehicles) {
                    return true
                } else false
            },
            friday() {
                let a = _filter(this.vehicleClasses, {
                    friday: true
                }).map(v => 
                    ({
                        id: v.id,
                        name: v.name,
                        ref: db.collection('classes').doc(v.id)
                    }))
                return a
            },
            saturday() {
                let b = _filter(this.vehicleClasses, {
                    saturday: true
                }).map(v =>

                    ({
                        id: v.id,
                        name: v.name,
                        ref: db.collection('classes').doc(v.id)
                    }))
                return b
            },
        
            countHooks() {
                let standard
                let reduced
                let v = this.vehicles
                
                let m = []

                if (v.length > 0) {
                    _.forEach(v, function(veh) {
                        let a = veh.vehicleRef.friday // Friday
                        let b = veh.vehicleRef.saturday // Saturday
                        if (a) {
                            _.forEach(a, function(ac){
                                m.push(ac)
                            })
                        } else {console.log("no Friday")}
                        if (b) {
                            _.forEach(b, function(bc) {
                                m.push(bc)
                            })
                        } else {console.log("no Saturday")}
                    })
                }
                
                reduced = _.filter(m, function(r) {return r.ref.price == "price/reduced" })
                standard = _.filter(m, function(s) {return s.ref.price  == "price/standard"})

                return {
                    hooks: m,
                    count: m.length,
                    standard: standard,
                    st_qt: standard.length,
                    reduced: reduced,
                    rd_qt: reduced.length
                }
                
                

            },
    
            lineItems() {
                let red
                let std
                let m=[]
                let domain = document.domain
                if (domain != 'midwestsuperpull.com') {
                    // test
                    red = "price_1MT4MWHCwTRZaEaBYLvV61Z4"
                    std = "price_1MSjUyHCwTRZaEaBMwROq5vM"
                } else {
                    // prod
                    red = "price_1MWXPgHCwTRZaEaBGq3UCNge"
                    std = "price_1MPytoHCwTRZaEaBCBkTMYFp"
                }

                let rQuantity = this.countHooks.rd_qt - this.currentlyBilled.types.reduced.count
                if (rQuantity < 0) {rQuantity = 0}
                else {console.log("rQuantity is", rQuantity)}

                let sQuantity = this.countHooks.st_qt - this.currentlyBilled.types.standard.count
                if (sQuantity < 0) {sQuantity = 0}
                else {console.log("sQuantity is", sQuantity)}

                let r = {
                    name: 'Hook Fee - Reduced Rate (Youth)',
                    price: red,
                    quantity: rQuantity
                }

                let s = {
                    name: 'Hook Fee - Standard Rate',
                    price: std,
                    quantity: sQuantity
                }

                if (sQuantity > 0) {m.push(s)} else {console.log('no standard hooks')}
                if (rQuantity > 0) {m.push(r)} else {console.log('no reduced hooks')}
                return m
                
               
            },
            pitPassLI() {
                let day
                let week
                let cost
                let priceType

                let domain = document.domain
                if (domain != 'midwestsuperpull.com') {
                    day = "price_1MT5KZHCwTRZaEaB7PKYJPYz"
                    week = "price_1MIJNzHCwTRZaEaBC4qrPDC5"

                    if (this.pitPassType = "weekend") {
                        cost = 10 * this.pitPass
                        priceType = week
                    } else {
                        cost = 15 * this.pitPass
                        priceType = day
                    }

                    return {
                        name: 'Pit Pass - '+this.pitPassType.toUpperCase(),
                        price: priceType,
                        quantity: this.pitPass,
                        subtotal: cost
                    }
                } 
                else {
                    console.log("prod prices not configured!")
                    week = "price_1MWXVcHCwTRZaEaBMnkKsg7P"

                    if (this.pitPassType = "weekend") {
                        cost = 10 * this.pitPass
                        priceType = week
                    } else {
                        cost = 15 * this.pitPass
                        priceType = day
                    }

                    return {
                        name: 'Pit Pass - '+this.pitPassType.toUpperCase(),
                        price: priceType,
                        quantity: this.pitPass,
                        subtotal: cost
                    }
                }
            }
            ,
            spectatorPassLI() {
                let day
                let week
                let cost
                let priceType

                let domain = document.domain
                if (domain != 'midwestsuperpull.com') {
                    day = 'price_1MWwAaHCwTRZaEaBxPPV2bP7'
                    week = "price_1MIJLTHCwTRZaEaBVrHR4tSv"

                    if (this.spectatorPassType = "weekend") {
                        cost = 10 * this.spectatorPass
                        priceType = week
                    } else {
                        cost = 10 * this.spectatorPass
                        priceType = day
                    }

                    return {
                        name: 'Spectator Pass - DAILY',
                        price: priceType,
                        quantity: this.spectatorPass,
                        subtotal: cost
                    }
                } 
                else {
                    //console.log("prod prices not configured!")
                    day = "price_1MWXYuHCwTRZaEaBSiWX11wl"
                    week = "price_1MWXYuHCwTRZaEaBSiWX11wl"

                    if (this.spectatorPassType = "weekend") {
                        cost = 10 * this.spectatorPass
                        priceType = week
                    } else {
                        cost = 10 * this.spectatorPass
                        priceType = day
                    }

                    return {
                        name: 'Spectator Pass - '+this.spectatorPassType.toUpperCase(),
                        price: priceType,
                        quantity: this.spectatorPass,
                        subtotal: cost
                    }
                }
            },
            sumPaidInvoices() {
                let sum = _sumBy(this.userSessions, function (p) {
                   
                    return p.paid
                })
                return sum
            },
            invoiceTotalMatch() {
                let a
                let b

                if (this.countHooks.count > 0) {
                    let a = this.countHooks.rd_qt * 25
                let b = this.countHooks.st_qt * 50
                if (this.sumPaidInvoices >= a+b) {
                    return true
                } else {
                    return false
                }
                } else {
                    return false
                }
                
            },
            successURL() {
                if (window.location.host == "localhost:8080") {
                    return 'http://'+window.location.host+'/#/order/' + this.$route.params.id +'/{CHECKOUT_SESSION_ID}'}
                    else {return 'https://'+window.location.host+'/#/order/' + this.$route.params.id +'/{CHECKOUT_SESSION_ID}' }
            },
            cancelURL(){
                if (window.location.host == "localhost:8080") {
                    return 'http://'+window.location.host+'/#/account/'+this.$route.params.id}
                    else {return 'https://'+window.location.host+'/#/account/'+this.$route.params.id}
            },
            
              hide(){
                if (this.invoiceTotalMatch != true) {return false} 
                else {return true}
                } 
            

            
        },
     

        methods: {

            classRef: function (a) {
                let c = db.collection("classes").doc(a)
                return c
            },
            getProducts(){
                const getStripeProducts = httpsCallable(functions, "getStripeProducts")
                this.stripeProducts = getStripeProducts
                
            },
            getPrice(type){
                if (type == 'hookfee') {
                    
                }
                else if (type == 'pitpass') {}
                else if (type == 'spectatorpass') {}
                else {console.log("no type")}
            },
            addToCart(hook,pp,sp){
                if (hook != '') {
                    let li = this.lineItems
                    let ct = this.cart
                    li.forEach(
                        function(a) {
                            ct.push(a)
                        }
                    )
                } 
                
                if (pp != '') {
                    (this.cart).push(this.pitPassLI)
                } 
                
                if (sp != '') {
                    (this.cart).push(this.spectatorPassLI)
                 } 
            },
            addVehicle: function () {
                // start transition/loading view
                let pf = this.performingRequest
                pf = true
                let form = document.getElementById("addVehicleForm")
                let doc = this.vehicleForm.name.toLowerCase().split(" ").join("-")
                let vf = this.vehicleForm
                this.vehicleForm.vehicleError = ''

                // add pull to Firestore
                let vehicleRef = db.collection("vehicles").doc(doc)
                let u = this.$route.params.id
                let f = this.vehicleForm
                let sh = this.vehicleForm.addVehicleForm
                vehicleRef.get()
                    .then((docSnapshot) => {
                        if (docSnapshot.exists) {
                            console.log("Vehicle already exists!")
                            let vE = "Another vehicle has registered with this name. Add a number or space to add your vehicle."
                            this.vehicleForm.vehicleError = vE
                            console.log(this.vehicleForm)
                        } else {
                            let r = db.collection("users").doc(this.user)
                            db.collection("vehicles").doc(doc).set({
                                name: this.vehicleForm.name,
                                vehicleDetails: this.vehicleForm.vehicleDetails,
                                userRef: r,
                                friday: this.vehicleForm.friday,
                                saturday: this.vehicleForm.saturday,
                                created: new Date(),
                            }).then(function (docRef) {
                                console.log("Vehicle written with ID: ", doc)
                                let vRf = db.collection("vehicles").doc(doc)
                                f.success = doc+' successfully created!'

                                db.collection("users").doc(u).collection("vehicles").doc(doc).set({
                                    vehicleRef: vRf,
                                    created: new Date()
                                })

                                
                            })
                        }       

                                if (this.vehicleForm.vehicleError != "") { console.log("error")}
                                else {
                                f.name = ''
                                f.friday = [],
                                f.saturday = [],
                                f.vehicleDetails = ''
                                f.vehicleForm.vehicleError = ""
                                f.addVehicleForm = false}
                    })


            },
            addClass: function () {
                let vehicle = this.vehicleForm.id
                let myVehicle = db.collection("vehicles").doc(vehicle)
                let newClassesFriday = this.vehicleForm.friday
                let newClassesSaturday = this.vehicleForm.saturday
                let vF = this.vehicleForm
                console.log('added class' + newClassesFriday + newClassesSaturday)

                myVehicle.update({
                    friday: newClassesFriday,
                    saturday: newClassesSaturday
                }).then(function () {
                    console.log("Class added!")
                    vF.friday = {}
                    vF.saturday = {}

                    vF.vehicleName = '',
                        vF.addVehicleClass = false
                }).catch(err => {
                    console.log(err)
                })
                /*myVehicle.update({
                    classes: firebase.firestore.FieldValue.arrayUnion(newClasses)
                }).then(function () {
                    console.log("Class added!")
                    vF.vehicleClasses = {}
                    vF.vehicleName = ''
                    vF.addVehicleClass = false
                })*/
            },
            addClassDialog: function (v) {
                this.vehicleForm.name = v.vehicleRef.name
                this.vehicleForm.id = v.id
                this.vehicleForm.vehicleClasses = v.classes
                this.vehicleForm.addVehicleClass = true


            },
            removeClass: function (v, c) {
                let vehicle = v.id
                let myVehicle = db.collection("vehicles").doc(vehicle)
                myVehicle.update({
                    classes: fb.FieldValue.arrayRemove(c)
                })
                //db.collection("vehicles").doc(vehicle).update({"classes": FieldValue.arrayUnion(classArray)})
                //db.FieldValue.arrayRemove({classArray})
            },
            delay: function (time) {
                return new Promise(resolve => setTimeout(resolve, time));
            },
            submit: function () {

                this.stripeloading = true

                const docRef = db
                    .collection('users')
                    .doc(this.$route.params.id)
                    .collection('checkout_sessions')
                    .add({
                        mode: "payment",
                        lineItems: this.stripeLI,
                        successURL: this.successURL,
                        cancelURL: this.cancelURL,
                        timestamp: new Date(),
                        hooks: this.countHooks
                    }).then(function (docRef) {
                        let n = docRef.id
                        return this.ckSession = n
                    })

                this.$refs.checkoutRef.redirectToCheckout()


            },
            close: function() {
                let f = this.vehicleForm
                f.name = ''
                f.vehicleDetails = ''
                f.friday = []
                f.saturday = []
                f.success = ''
                f.addVehicleForm = false
            }
        },
        components: 
        {StripeCheckout,
    "deleteTractor": deleteTractor}
    }
</script>

<style>
.centered-input input {
  text-align: center;
  font-size: 1.5em;
  font-weight: 700;

}
</style>