<template>
<div>
    <v-container fluid>
        <v-card elevation="0">
            <p class="text-center">For live results and schedule, go to midwestsuperpull.com/#/schedule</p>
            <v-img contain src="https://firebasestorage.googleapis.com/v0/b/midwestsuperpull.appspot.com/o/Untitled.png?alt=media&token=47e8356a-0b37-485f-b76c-b612b556711a" height="175"></v-img>
            <v-card-title class="mb-10">
                <h1 class="display-3 text-center mx-auto">
                    <span v-if="data.day == '2022-04-08'">{{data.details.fridayOrder}} - </span>
                     <span v-if="data.day == '2022-04-09'">{{data.details.saturdayOrder}} - </span>
                    {{data.details.name}}</h1>
            </v-card-title>
            <v-card-text>    
            <v-simple-table style="font-size:1.4em;">
                <tr style="font-size:1.5em;">
                    <th class="text-center">Hook</th>
                    <th class="text-left">Tractor</th>
                    <th class="text-left">Driver</th>
                </tr>
                <tr v-for="h in data.hooks" :key="h.index" style="line-height:3em;">
                    <td class="text-center text-capitalize">
                        {{h.hook}}
                    </td>
                    <td class="text-capitalize">{{h.name}}</td>
                    <td v-if="h.driver" class="text-capitalize">{{h.driver}}</td>
                    <td v-else class="text-capitalize">{{getUser(h.user)}}</td>
                </tr>
            </v-simple-table>
            </v-card-text>
        </v-card>
    </v-container>

</div>
    
</template>
<script>
import {db, classes,users} from '../../firebaseconfig'
import _ from 'lodash'
import { QuerySnapshot } from '@firebase/firestore'
export default {
    name: 'print',
    data() {
        return {
            group: [],
            users: users,
            classes: classes
        }
    },
    firestore() {
        return {
            
        }
    },
    computed: {
         data() {
            return this.$route.params.data
        },
    },
        methods: {
            getUser(user) {
                console.log("userId", user)
                let a = _.filter(this.users,function(u){
                    return u.id === user
                })
                a = a[0]
                console.log("printuser",a)
                return a.firstName+' '+a.lastName
            }
        }

    
}
</script>

<style>
@media print {
    body {
      overflow: auto;
      height: auto;
    }

    .pgBreak {
      page-break-after: always;
      margin-top: 50px;
    }

    .no-print {
      display: none !important;
    }

    .pullDetails {
      position: fixed;
      top: 0;
      width: 100%;
    }

  }
</style>
