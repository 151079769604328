<template>
<div :data-date="now">
    
<div id="spd" class="fluid d-flex justify-center align-center">
    <v-card class="justify-center" color="transparent" flat dark>
        <v-card-title style="line-height:1.9em;"><h1>Sponsors</h1></v-card-title>
        <v-card-text>
            <div id="head">
                
                The Midwest Spring Super Pull<br class="hidden-sm-and-up">
                is made possible by the generous<br class="hidden-md-and-up">
                support of our sponsors.
            </div>
        </v-card-text>
        <v-card-actions>
        <v-btn href="mailto:midwestsuperpull@gmail.com" class="" dark color="red darken-3">Become a Sponsor</v-btn>
        </v-card-actions>
    </v-card>
   
</div> 
<v-container>
    
    <v-row>
        <v-col>
    <h1>Our Sponsors</h1>

        </v-col>
    </v-row>

        <v-row v-if="platinum.length >= 1">
        <v-container>
        <h2 class="text-h4">Spotlight</h2>
        <p>These sponsors are the official hosts of the Midwest Spring Super Pull (MSSP).</p>
        </v-container>
    </v-row>
    <v-row class="d-flex align-stretch justify-center" v-if="platinum.length >= 1">
        <v-col v-for="p in platinum" :key="p.id" class="col-md-3 mx-auto">
        <v-card outlined flat>
            <v-img height="250" :src="p.img" contain dark v-if="p.img !=''"></v-img>
            <v-avatar color="grey lighten-2" size="56" v-else></v-avatar>
            <v-list-item>
                <v-list-item-content>
                    <v-list-item-title>{{p.name}}</v-list-item-title>
                    <v-list-item-subtitle>{{p.description}}</v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-avatar size="48"><v-btn text :href="p.website" target="_blank"><v-icon>mdi-open-in-new</v-icon></v-btn></v-list-item-avatar>
            </v-list-item>
        </v-card>
    </v-col>
    </v-row>

      <v-row v-if="silver.length >= 1">
        <v-container class="mt-5">
        <h2 class="text-h4">Partners</h2>
        <p>These sponsors have contribued time and effort to making MSSP a success. We are thankful for their support and encourage attendees to patronize their businesses.</p>
        

    <v-row class="justify-center mb-5" v-if="silver.length >= 1">
        <v-col v-for="s in silver" :key="s.id" class="col-md-2 col-sm-6" >
        <v-card flat>
            <v-img :src="s.img" contain dark v-if="s.img !=''" style="max-height:6em;"></v-img>
            <v-col v-else class="mx-auto text-center"><v-avatar color="grey lighten-2" size="70" >{{s.avatar}}</v-avatar></v-col>
            <v-list-item>
                <v-list-item-content>
                    <v-list-item-title>{{s.name}}</v-list-item-title>
                    <v-list-item-subtitle>{{s.description}}</v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-avatar size="48"><v-btn text :href="s.website" target="_blank"><v-icon>mdi-open-in-new</v-icon></v-btn></v-list-item-avatar>
            </v-list-item>
        </v-card>
    </v-col>
    </v-row>
        </v-container>
    
      </v-row>
    
    
  
    
     

</v-container>
</div>
</template>
<script>
import {db, sponsors} from '../../firebaseconfig'

export default {
    name: "sponsors", 
    data() {
        return {
            sponsors:{},
            levels: [
                {name:'Platinum',
                amount:'$1,000+',
                logo: "website front page, event flyers, Facebook post, full page in event program",
                benefits: "banners (supplied by sponsor) along track and in concession area, name announced during event, 6 weekend passes for entry into event."},
                {name:'Gold',
                amount: '$601-$999',
                logo: "website front page, event flyers, Facebook post, logo in event program",
                benefits: "banners (supplied by sponsor) along track and in concession area, name announced during event, 4 weekend passes for entry into event."},
                 {name:'Silver',
                amount: '$251-600',
                logo: "website front page, event flyers, Facebook post, logo in event program",
                benefits: "banners (supplied by sponsor) along track, name announced during event, 2 weekend passes for entry into event."},
                 {name:'Bronze',
                amount: '$50-250',
                logo: "website class page, event flyers, logo in event program",
                benefits: "banners (supplied by sponsor) along track, name announced during event."},

            ],
        }
    },
    firestore() {
        return {
            sponsors: db.collection("sponsors").where("years","array-contains",2023)
        }
    },
    computed: {
        platinum() {
            let n = this.sponsors.filter(sponsor => sponsor.level=="platinum")
            let o = n.sort((firstItem,secondItem) => firstItem.order - secondItem.order)
            return o
        },
        gold() {
            let p = this.sponsors.filter(sponsor => sponsor.level=="gold")
            let q = p.sort((firstItem,secondItem) => firstItem.order - secondItem.order)
            return q
        },
        silver() {
            let p = this.sponsors.filter(sponsor => sponsor.level=="silver")
            let q = p.sort((firstItem,secondItem) => firstItem.order - secondItem.order)
            return q
        },
        bronze() {
            let p = this.sponsors.filter(sponsor => sponsor.level=="bronze")
            let q = p.sort((firstItem,secondItem) => firstItem.order - secondItem.order)
            return q
        },
        now() {
            return new Date()
        },
        height: function () {
        switch (this.$vuetify.breakpoint.name) {
          case 'xs': return 100
          case 'sm': return 125
          case 'md': return 150
          case 'lg': return 175
          case 'xl': return 200
        }}
    },
    methods: {
        addSponsor: 
            function() {
                let sm = this.successMessage
                let mid = ''
                let r = this.router
                db.collection("requests").add({
                    
                    name: this.sponsorForm.name,
                    name: this.sponsorForm.contact,
                    address: this.sponsorForm.address,
                    phone: this.sponsorForm.phone,
                    email: this.sponsorForm.email,
                    sponsorLevel: this.sponsorForm.sponsorLevel,
                    created: new date()
                }).then(function(docRef) {
                    mid = docRef.id
                    console.log("sponsor request written with",docRef.id)
                    sm = "Sponsor request" + mid + " added!"
                    console.log(sm)
                    return this.sponsorForm.successBanner == true
                }).catch(err => {
                    this.sponsorForm.errorMessage = err.mesage
                    console.log("error ", err.message)
                })
            }
    }
}
</script>

<style>

#spd {
   background:linear-gradient(145deg, rgba(133, 22, 22, 0.905), rgba(247, 128, 108, 0.6)), url(https://firebasestorage.googleapis.com/v0/b/midwestsuperpull.appspot.com/o/classes%2F1950-mini-rod.jpg?alt=media&token=4dc8a18a-fbfc-46f9-85a7-8670b18fdb5c) no-repeat center center fixed; 
   filter: contrast(80%);
   height: 250px;
   width: 100%;
   min-height:250px;
   background-attachment: fixed;
   background-position:bottom 2px right;
   background-repeat:no-repeat;
   background-size:cover;
}

</style>