<template>
<v-container fluid id="">
    <v-row>
        <v-container class="text-center">
        <h2 class="text-center" style="font-size:2.25em;">Spotlight Sponsors</h2>
        <p>We'd like to thank our sponsors for making this event possible. Learn more about our sponsors or becoming a sponsor <router-link to="/sponsors">here</router-link>.</p>
        </v-container>
    </v-row>
    <v-row v-if="platinum.length >= 1">
        <v-container class="text-center">
        </v-container>
    </v-row>
    <v-row class="d-flex align-stretch justify-center" v-if="platinum.length >= 1">
      
        <v-col md=6 class="d-flex align-stretch justify-center" v-for="p in platinum" :key="p.id">
            <v-card :href="p.website" flat>
                <v-img height="200px" :src=p.img contain></v-img>
            </v-card>
        </v-col>
  
    </v-row>
    <v-row v-if="gold.length > 0">
        <v-container class="text-center">
        <h3 class="text-h5 text-center">Gold</h3>
        </v-container>
    </v-row>
    <v-row class="d-flex align-stretch justify-center" v-if="gold.length >= 1">
      
        <v-col md=6 class="d-flex align-stretch justify-center" v-for="g in gold" :key="g.id">
            <v-card :href="g.website" flat>
                <v-img max-height="150px" :src=g.img contain></v-img>
            </v-card>
        </v-col>
    </v-row>
    
  
    
</v-container>

</template>


<script>
import {db,sponsors} from '../../firebaseconfig.js'

export default {
    name: 'spotlightsponsors',
    data() {
        return {
            spotlightSponsors: [],
            sponsors: {}
        }
    },
    firestore() {
            return {
                sponsors
            }
        },
    computed: {
        platinum() {
            let n = this.sponsors.filter(sponsor => sponsor.level=="platinum")
            let o = n.sort((firstItem,secondItem) => firstItem.order - secondItem.order)
            return o
        },
        gold() {
            let p = this.sponsors.filter(sponsor => sponsor.level=="gold")
            let q = p.sort((firstItem,secondItem) => firstItem.order - secondItem.order)
            return q
        }
    }
}
</script>

<style>
#gah {
    background-color: black;
    color: white;
    padding-top:2em;

}
</style>