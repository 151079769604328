import Vue from 'vue'
import VueRouter from 'vue-router'
//import { component } from 'vue/types/umd'
import Home from '../views/Home.vue'
import hero from '../components/hero.vue'
import location from '../components/location.vue'
import mailing from '../components/mailing.vue'
import spotlightsponsors from '../components/spotlightsponsors.vue'
import sponsorevent from '../components/sponsorevent.vue'
import sponsors from '../views/sponsors.vue'
import rules from '../views/rules.vue'
import classes from '../views/classes.vue'
import classDetails from '../views/classDetails.vue'
import register1 from '../views/register1.vue'
import login from '../views/login.vue'
import account from '../views/account.vue'
import success from '../views/success.vue'
import cancel from '../views/cancel.vue'
import admin from '../views/admin.vue'
import manageClasses from '../views/manageClasses.vue'
import previousYearResults from '../views/previousYearResults.vue'
import schedule from '../views/schedule.vue'
import print from '../views/print.vue'
import printForm from '../views/printForm.vue'
import addRegistration from '../views/addRegistration.vue'


import About from '../views/About.vue'
import {db, getDoc, addDoc} from '../../firebaseconfig'
import {getAuth, onAuthStateChanged} from 'firebase/auth'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      title: 'Midwest Spring Super Pull',
      content: 'Official home page of the Midwest Spring Super Pull.'
    },
    children: [
      {path: 'hero',
      component: hero},
      {path: 'location',
    component: location},
    {path: 'mailing',
    component: mailing},
    {path: 'spotlightsponsors',
    component: spotlightsponsors},
    {path: 'sponsorevent',
    component: sponsorevent}
    ],
  },
  {
    path:'/admin/classes',
    name: 'manageClasses',
    component: manageClasses
  },
  {
    path: '/sponsors',
    name: 'sponsors',
    component: sponsors,
    meta: {
      title: "MSSP Sponsors",
      content: "Our sponsors make the Midwest Spring Super Pull possible"
    }
  },
  {
    path: '/schedule',
    name: 'schedule',
    component: schedule,
    meta: {
      title: 'MSSP - Standings',
      content: 'Find pull results for MSSP 2024'
    },
  },
  {
    path: '/results',
    name: 'previousYearResults',
    component: previousYearResults,
  },
  
  {
    path:'/admin',
    name: 'admin',
    component: admin,
    meta: {
      requiresAuth:true,
      title: "MSSP Admin",
      content: "Admin Portal for MSSP"
    }
  },
  {
    path: '/register',
    name: register1,
    component: register1,
    meta: {
      title: "MSSP Registration",
      content: "Register for MSSP 2024"
    }
  },
  
  {
    path: '/rules',
    name: 'rules',
    component: rules
  },
  {
    path: '/classes',
    name: 'classes',
    component: classes,
    meta: {
      title: "MSSP - Classes",
      content: "Pulling classes for MSSP 2024"
    }
  },
  {
    path: '/class/:id',
    name: 'classDetails',
    component: classDetails,
    params: {
      classDetails
    }
  },
  {
    path: '/admin/addRegistration',
    name: 'addRegistration',
    component: addRegistration,
    params: {
      
    }
  },
  {
    path: '/about',
    name: 'About',
    component: About
  },
  {
    path: '/order/:user/:id',
    name: 'Order Success',
    component: success
  },
  {
    path: '/order/cancel',
    name: 'Order Cancel',
    component: cancel
  },
  {
    path: '/print/',
    name: 'print',
    component: print
  },
  {
    path: '/printForm/',
    name: 'printForm',
    component: printForm,
    meta: {
      name: 'MSSP - Print Form'
    }
  }
]

const router = new VueRouter({
  routes
})



export default router
