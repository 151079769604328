<template>
<v-container>
 
    <v-row>
        <v-col cols="12">
            <v-row>
                <v-col>
                    <v-card>
                        <v-sheet color="#f2f2f2" class="d-flex justify-center align-center" elevation="0">
                            <div style="color:#131231;"><h1>Admin</h1></div>
                        </v-sheet>
                    </v-card>
                </v-col>
            </v-row>
        </v-col>
        <v-col cols="12">
            <admin-login v-if="!loggedIn"></admin-login>
        </v-col>
    </v-row>
    <v-row>
        <v-col cols="12">
            <form-management v-if="admin"></form-management>
        </v-col>
    </v-row>
</v-container>

</template>
<script>
import {db, classes} from '../../firebaseconfig'
import adminLogin from '../components/adminLogin.vue'
import formManagement from '../components/formManagement.vue'

import _ from 'lodash'
import {
        getAuth,
        onAuthStateChanged,
        signOut
    } from "firebase/auth";
import { doc, collection } from 'firebase/firestore'

export default {
    name: 'admin',
    data() {
        return {
            sessionLoading: false,
            tab:0,
            search: '',
            classes: [],
            now: new Date(),
            registrationForms: []
        }
    },
    firestore() {
        return {
            registrationForms: db.collection("mailRegistrationForms").orderBy("paid")
        }
    },
    props: {
    },
    
    computed: {
        admin() {
            return sessionStorage.admin
        }, 
        loggedIn() {
            return sessionStorage.loggedIn
        }
       
       
        
    },
    mounted() {
        if (sessionStorage.admin) {
            console.log("admin")
        }
    },
    watch: {
        admin(newAdmin) {
            sessionStorage.admin = newAdmin
        }
    },
    components: {adminLogin, formManagement},
    methods: {
             }
            
    
}
</script>