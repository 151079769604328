<template>
    <div class="mt-5">
        <h1 class="text-center">Become a Sponsor</h1>
        <v-container fluid>
        <v-row>
            
<v-col md=6>
    <v-img src="../assets/shutterstock_557655568.jpg"  class="align-self-center" />
</v-col>
<v-col md=6>
    <p>Interested in becoming a sponsor? Fill out this form.</p>
    <v-form>
        <v-text-field v-model="sponsorname" label="Sponsor/Business"></v-text-field>
        <v-text-field v-model="contactname" label="Contact Name"></v-text-field>
        <v-text-field v-model="email" label="Email"></v-text-field>
        <v-text-field v-model="phone" label="Phone"></v-text-field>
    </v-form>
</v-col>
            
        </v-row>
        </v-container>
    </div>
</template>


<script>
export default {
    name: 'sponsorevent',
    data: () => ({
        platinumSponsors: '',
        sponsorname: '',
        contactname: '',
        email: '',
        phone: '',
        sponsorlevel: '',
        levels: ['Platinum > $1,000', 'Gold > $601', 'Silver > $251', 'Bronze > $50']
    }),
    
}
</script>

<style>
label {
    font-family: Arial, Helvetica, sans-serif !important;
    font-size: 0.95em !important;
    line-height: 0.9em !important;
} 
</style>