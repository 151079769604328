<template>
        <v-expansion-panel class="pgBreak">
            <v-expansion-panel-header :color="highlightColor" class="noprint">
        <div class="align-right">
        <span v-if="day == '2024-04-05'"><v-avatar color="grey lighten-4">{{details.fridayOrder}}</v-avatar> <b>{{details.name}}</b></span>
       <span v-else-if="day == '2024-04-06'"><v-avatar color="grey lighten-4">{{details.saturdayOrder}}</v-avatar> <b>{{details.name}}</b></span>  
       <!-- <span v-if="day == '2023-04-07'"><v-avatar color="grey lighten-4">{{details.fridayOrder}}</v-avatar> <b>{{details.name}}</b></span>
       <span v-else-if="day == '2023-04-08'"><v-avatar color="grey lighten-4">{{details.saturdayOrder}}</v-avatar> <b>{{details.name}}</b></span>            
        <span v-else-if="day == '2022-04-08'"><v-avatar color="grey lighten-4">{{details.fridayOrder}}</v-avatar> <b>{{details.name}}</b></span>
       <span v-else-if="day == '2022-04-09'"><v-avatar color="grey lighten-4">{{details.saturdayOrder}}</v-avatar> <b>{{details.name}}</b></span>-->
       <span v-else><v-avatar color="grey lighten-4">{{details.testOrder}}</v-avatar> <b>{{details.name}}</b></span></div>
    
       <template v-slot:actions>
           <v-btn @click="printSection" class="noprint" v-if="admin" text color="grey lighten-1">Print</v-btn>
            <v-btn :color="scale" outlined>{{hooks.length}}</v-btn>
          </template>
       
            </v-expansion-panel-header>
            <v-expansion-panel-content :id="id">
            <v-row v-if="admin">
                <v-col>
            <div class="text-h6 mt-3">{{details.name}}</div>
                </v-col>
                <v-col v-if="admin && editFullPull" class="ml-auto" cols=4 md=3>
                    <v-text-field label="Full Pull" v-if="day=='2024-04-05'" v-model="fullPull.friday" type="number" outlined append-outer-icon="mdi-content-save" @click:append-outer="editClass(fullPull)"></v-text-field>
                    <v-text-field label="Full Pull" v-if="day=='2024-04-06'" v-model="fullPull.saturday" type="number" outlined append-outer-icon="mdi-content-save" @click:append-outer="editClass(fullPull)"></v-text-field>
                    <v-text-field label="Full Pull" v-if="day=='2022-04-08'" v-model="fullPull.friday" type="number" outlined append-outer-icon="mdi-content-save" @click:append-outer="editClass(fullPull)"></v-text-field>
                    <v-text-field label="Full Pull" v-if="day=='2022-04-09'" v-model="fullPull.saturday" type="number" outlined append-outer-icon="mdi-content-save" @click:append-outer="editClass(fullPull)"></v-text-field>
                    <v-text-field label="Full Pull" v-if="day=='2023-04-07'" v-model="fullPull.friday" type="number" outlined append-outer-icon="mdi-content-save" @click:append-outer="editClass(fullPull)"></v-text-field>
                    <v-text-field label="Full Pull" v-if="day=='2023-04-08'" v-model="fullPull.saturday" type="number" outlined append-outer-icon="mdi-content-save" @click:append-outer="editClass(fullPull)"></v-text-field>
                </v-col>
                <v-col v-else-if="admin && !editFullPull" class="ml-auto" cols=4 md=3>
                    <v-text-field label="Full Pull" v-model="fullPullDay" type="number" outlined readonly append-outer-icon="mdi-pencil" @click:append-outer="edit1"></v-text-field>
                </v-col>
                <v-col v-if="admin && !heat" class="ml-auto" cols=4 md=3>
                    <v-text-field label="Heat" v-model="heat" type="boolean" outlined readonly append-outer-icon="mdi-pencil" @click:append-outer="editHeat1"></v-text-field>
                </v-col>
            </v-row>
            <v-row>
                <v-col>
                    <v-select filled v-model="sort" :items="sortOptions" :value="sort" persistent-hint hint="All hooks sorted by hook order unless otherwise selected." dense prefix="Sort by:" flat @keyup="sorted(hooks,sort)" background-color="#F2F2F2" color="primary" style="margin-top:-10px;"></v-select>
                </v-col>
            </v-row>
            <div v-if="day === '2024-04-05'">
            <hook-detail v-for="h in sorted(hooks,sort)" :key="h.index" :hook="h" :order="order(h,details,day)"  :admin="admin" :day="day" :fullPull="Number(fullPullDay)"/>
            </div>
            <div v-else-if="day === '2024-04-06'">
                <hook-detail v-for="h in sorted(hooks,sort)" :key="h.index" :hook="h" :order="order(h,details,day)"  :admin="admin" :day="day" :fullPull="Number(fullPullDay)"/>
            </div>
            <div v-if="day === '2023-04-07'">
            <hook-detail v-for="h in sorted(hooks,sort)" :key="h.index" :hook="h" :order="order(h,details,day)"  :admin="admin" :day="day" :fullPull="Number(fullPullDay)"/>
            </div>
            <div v-else-if="day === '2023-04-08'">
                <hook-detail v-for="h in sorted(hooks,sort)" :key="h.index" :hook="h" :order="order(h,details,day)"  :admin="admin" :day="day" :fullPull="Number(fullPullDay)"/>
            </div>
            <div v-else-if="day === '2022-04-08'">
            <hook-detail v-for="h in sorted(hooks,sort)" :key="h.index" :hook="h" :order="order(h,details,day)"  :admin="admin" :day="day" :fullPull="Number(fullPullDay)"/>
            </div>
            <div v-else-if="day === '2022-04-09'">
                <hook-detail v-for="h in sorted(hooks,sort)" :key="h.index" :hook="h" :order="order(h,details,day)"  :admin="admin" :day="day" :fullPull="Number(fullPullDay)"/>
            </div>
            <div v-else>
                
            </div>
      
            </v-expansion-panel-content>
        </v-expansion-panel>
 
</template>

<script>
import {db} from '../../firebaseconfig'
import hookDetail from '../components/hookDetail'
import _ from 'lodash'
export default {
    name: 'hookClassDay',
    data() {
        return {
            hooks: [],
            open: [1,2],
            currentYear: {
                friday: '2024-04-05',
                saturday: '2024-04-06'
            },
            colors: {
                '1': 'grey',
                '2': 'red darken-3',
                '3': 'orange',
                '4': 'yellow darken-3',
                '5': 'green'
            },
            highlight: false,
            highlightColor: '',
            fullPull: {
                friday: null,
                saturday: null
            },
            editFullPull: false,
            heat: false,
            sort: 'Hook Order',
            sortOptions: ['Hook Order','Distance','Pull Off', 'Heat']
        }
    },
    props: {
        'id': String,
        'day': String,
        'details': Object,
        'admin':Boolean
    },
    firestore() {
        return {
            hooks: db.collection('classes').doc(this.id).collection(this.day).orderBy("hook", "asc")
        }
    },
    components: {
        'hookDetail':hookDetail
    },
    methods: {
        printSection() {
            let data = {
            day: this.day,
            details: this.details,
            hooks: this.hooks
        }
            this.$router.push({name:'print', params: {data}})
},
        order(h,c,d) {
            if (d == "2022-04-08") {return h.hook + c.fridayOrder }
            else if (d == "2022-04-09") {return h.hook + c.saturdayOrder}
            else if (d == "2022-04-08") {return h.hook + c.fridayOrder }
            else if (d == "2023-04-08") {return h.hook + c.saturdayOrder}
            else if (d == "2023-04-07") {return h.hook + c.fridayOrder }
            else if (d == "2024-04-05") {return h.hook + c.fridayOrder }
            else if (d == "2024-04-06") {return h.hook + c.saturdayOrder}
            else if (d == "test") {return h.hook + c.testOrder}
            else (alert("Something is VERY wrong!"))

            console.log(d)
        },
        edit1(){
            if (this.details.fullPull) {this.fullPull = this.details.fullPull}
            this.editFullPull = !this.editFullPull
        },
        editHeat1(){
            if (this.details.heat) {this.heat = this.details.heat}
            this.heat = !this.heat
        },
        editClass(fp){
            var fpnum ={
                fullPull:{
                    friday: Number(fp.friday),
                    saturday: Number(fp.saturday)
                }
            }
            db.collection('classes').doc(this.id).update(fpnum).then(()=>{console.log("updated class with fp",fp); this.editFullPull = !this.editFullPull}).catch((error)=>{console.log('Full pull',error)})
        },
        sorted(h,s) {
            console.log(s, 'start')
            var t = "pullOff.distance"
            let a = []
            if (s == 'Hook Order') {a = h}
            else if (s == 'Distance') 
                {a = _.orderBy(h, function(d){return Number(d.distance)},'desc')
                console.log(a, 'distance')}
            else if (s == 'Pull Off') 
                {a = _.orderBy(h,function(d){
                    let bb
                    if (d.pullOff) {bb = Number(d.pullOff.distance) + Number(d.distance)}
                    else if (Number(d.distance)>0) { bb = Number(d.distance) }
                    else {bb = Number(d.hook)-100}
                    return bb
                    },'desc')
                console.log(a,'pull off')}
            else if (s == 'Heat') {
                a = _.orderBy(h, function(d){
                     
                    if (d.finalHeat) {
                        return d.finalHeat.distance + 500
                    } else {
                        return d.distance
                    }
                }, 'desc') 
            }
            else (alert("Something is broken"))
            
            return a
        }
       
    },
    computed: {
       fullPullDay() {
           if (this.details.fullPull) {
               if (this.day == this.currentYear.friday) {
                   return this.details.fullPull.friday
               } else if (this.day == this.currentYear.saturday) {
                   return this.details.fullPull.saturday
               } else if (this.day == 'test') {
                   return this.details.fullPull.test
               }
               else {console.log("No valid date.")}
           }
           else {console.log("No full pull set!")}
       },
       editIcon(){
           if (this.editFullPull) {
               return 'mdi-content-save-settings'
           } else {return 'mdi-pencil'}
       },
        
        scale() { 
            let h = this.hooks.length
            let c= this.colors
        if (h <= 1) {return c[1]}
        else if (h >= 5) {return c[5]}
        else if (h == 2) {return c[2]}
        else if (h == 3) {return c[3]}
        else if (h == 4) {return c[4]}
        else {alert("Something is very wrong!")}
        }
    }

}
</script>

<style>
@media print {
    
    body {
      overflow: auto;
      height: auto;
    }

    .pgBreak {
      page-break-after: always;
      margin-top: 50px;
    }
    .toprint {
        display: block !important;
    }
    .noprint {
      display: none !important;
    }

    .pullDetails {
      position: fixed;
      top: 0;
      width: 100%;
    }

  }
</style>