<template>
    <div>
        <v-container>
            <h1>Class List</h1>
            <v-row>
                <v-col v-for="c in classes" :key="c.name" cols="12" sm="6" md="4" lg="3">
                <v-card dark min-height="90px" :to="{name:'classDetails',params:{id: c.id, classDetails:c}}">
                    <v-img :src="c.img" max-height="90px" min-height="90px" class="d-flex align-center text-center"
                    gradient="to top right, rgba(100,115,201,.33), rgba(25,32,72,.7)"
                    >
                        <h2>{{c.name}}</h2>
                    </v-img>
                
                </v-card>
                </v-col>
            </v-row>
            <v-row>
                <v-col v-for="s in sponsors" :key="s.id" class="d-inline-flex align-center justify-start">
                    <v-img :src="s.img"  width="6em" v-if="s.img" style="width:6em !important; max-width:100px;"></v-img>
                    <v-avatar v-else color="grey lighten-3" size="4em">{{s.avatar}}</v-avatar>
                </v-col>
                
            </v-row>
        </v-container>
    </div>
</template>


<script>
import {classes,sponsors} from '../../firebaseconfig.js'

export default {
    name: 'classes',
    data() {
        return {
            classes: classes,
            sponsors: []
        }
    },
    firestore() {
        return {
            
            'sponsors': sponsors
        }
    }
}
</script>

