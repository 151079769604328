// store.index.js

import Vue from 'vue'

export const userStatus = Vue.observable( {
  user: {},
  admin: false,
  billing: false,
  weighIn: false,
  uid: ''

})

export default Vue.observable({
  today: new Date()
})

