<template id="hotels">
    <div class="mt-10 mb-5">
        <v-row>
        <v-col cols=12 sm=10 md=8 class="mx-auto">
          <h1 class="text-center" style="margin:0; line-height: 0.9em;"> Room Discounts Available</h1>
        </v-col>
    </v-row>
    <v-row class="mx-auto" align="stretch">
        <v-col v-for="h in hotels" :key="h.id" cols="12" sm="5" class="mx-auto d-flex flex-column">
        <v-card :href="h.url" class="d-flex flex-column flex" >
            <v-img :src="h.image" cover height="150"></v-img>
            <v-card-title>{{ h.hotel }}</v-card-title>
            <v-card-subtitle>{{ h.address }}</v-card-subtitle>
            <v-card-text>
                <div>
                    <ul>
                        <li>Deadline for Discount: {{ h.deadline }}</li>
                        <li v-if="h.contactPerson != ''">Contact Person: {{ h.contactPerson }}</li>
                        <li v-if="h.groupNumber != ''">Group Number: {{ h.groupNumber }}</li>
                        <li>Contact Phone:{{ h.contactPhone }}</li>
                    </ul>
                </div>
            </v-card-text>
            <v-card-actions>
                <v-row>
                    <v-col>
                <v-btn block text :href="callHotel(h.contactPhone)">Call</v-btn>
            </v-col>
            <v-col>
                <v-btn block text :href="url">Reserve</v-btn>
            </v-col>
            </v-row>
            </v-card-actions>
        </v-card>
        </v-col>
    </v-row>
    </div>
</template>

<script>

export default {
    name: 'hotels',
    data() {
        return {
            hotels: [
            {hotel: 'AmericInn by Wyndham', 
                deadline: 'March 1, 2024',
                contactPerson: 'Jen Krupenny',
                image: '../assets/americ-inn@2x-2.webp',
                contactPhone: '715-256-3993',
                url: 'https://www.wyndhamhotels.com/americinn/new-richmond-wisconsin/americ-inn-hotel-and-suites-new-richmond/overview',
                address: '1020 South Knowles Ave., New Richmond, WI, 54017-2546',
                groupNumber: ''
            },
            {   hotel: 'Best Western Plus',
                deadline: 'March 25, 2024',
                contactPerson: '',
                image: '../assets/BWPlus.jpg',
                contactPhone: '715-243-5600',
                url: 'https://www.bestwestern.com/en_US/book/hotel-rooms.50146.html?groupId=C52XT6G0',
                address: '240 Paperjack Dr, New Richmond, WI 54017',
                groupNumber: 'C52XT6G0'

            }
        ]
        }
    },
    methods: {
        callHotel: function(phone){
            let ph = phone.replace(/-/g, "")
            return 'tel:+1'+phone+"'"
        }
    }
}
</script>
