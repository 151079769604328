<template>
<div>
    <v-container>
        <v-row>
            <v-col cols="12">
                <v-card color="light-green lighten-3" class="text-center" flat>
                    <v-card-text>
                        <v-card-title class="text-center"><h1 class="text-center mx-auto">Success!</h1></v-card-title>
                        <v-card-subtitle>We've received your payment and registration.</v-card-subtitle>
                        <p>Continue to your account to review your registration. You can view and manage your hooks on the day in your profile.</p>
                    </v-card-text>
                </v-card>
            </v-col>
            <v-col cols="12" md="6" style="margin-top:-1.5em;">
                <v-card flat>
                    <v-card-text>
                        <v-card-title>Order Summary</v-card-title>
                        <v-col style="margin-top:-10px">
                        <v-text-field filled readonly :value="userDetails.firstName" label="First Name" color="deep-orange darken-5"></v-text-field>
                        <v-text-field filled readonly :value="userDetails.lastName" label="Last Name" color="deep-orange darken-5"></v-text-field>
                        
                        </v-col>

                    
                        <v-simple-table light  >
                            <template v-slot:default>
                            <thead>
                                <tr>
                            <td class="overline">Item</td>
                            <td class="overline">Quantity</td>
                        </tr>
                        </thead>
                   
                    <tbody>
                        <tr v-for="l in lastSessionFB.lineItems" :key="l.index">
                           
                            <td>{{ products(l.price) }}</td>
                            <td> {{ l.quantity }}</td>
                        </tr> 
                        <tr>
                            <td>Total Paid:</td>
                        <td>${{ amountPaid }}</td></tr>
                    </tbody>
                        </template>
                        </v-simple-table>
                        <v-col>
                        <v-btn @click="account" color="success darken-5" dark block>Return to Account</v-btn>
                        </v-col>
                    </v-card-text>
              
                    <v-card-actions>
                        <v-row>
                            <v-spacer></v-spacer>
                        
                        </v-row>
                    </v-card-actions>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</div>
</template>
<script>
import {db} from '../../firebaseconfig'
import _ from 'lodash'
import { doc, updateDoc, addDoc, setDoc, collection} from 'firebase/firestore'
import {getAuth, onAuthStateChanged} from 'firebase/auth'
import { StripeCheckout } from '@vue-stripe/vue-stripe'

export default {
    name: "success",
    data() {
        this.publishableKey = process.env.STRIPE_PUBLISHABLE_KEY;
        return {
            user: this.$route.params.user,
            userDetails: {},
            ck_session: this.$route.params.id,
            testKey: {},
            lastSession: {},
        }
    },
    firestore() {
        return {
            userDetails: db.collection("users").doc(this.user),
            lastSession: db.collection("users").doc(this.user).collection("checkout_sessions").orderBy("timestamp",'desc').limit(1),
            testKey: db.collection("applications").doc("stripe")
        }
    },
    created() {
            const auth = getAuth()
            let a = this.testKey.publishableKey
            onAuthStateChanged(auth, (user) => {
                if (user) {
                    const b = user
                    const uid = b.uid
                    console.log(uid)
                } else {
                    // User is signed out
                }
            })

            if (this.ck_session) {
                fetch('https://api.stripe.com/v1/checkout-session?sessionId='+this.ck_session,
                {
                    method: 'GET',
                    api_key: a
                }
                ).then(function(response){
                    return response.json()
                })
               .catch(function (err) {console.log('Error when fetching Checkout session', err)})
            }

        }, 
    mounted: function() {
        //this.paymentSuccess()
    },
    computed: {
        lastSessionFB: function() {
            //console.log(this.lastSession[0].key+'last session key')
            db.collection("users").doc(this.user).collection("checkout_sessions").doc()
            return this.lastSession[0]
        },
        amountPaid: function() {
            const pitPass = ['price_1MIJNzHCwTRZaEaBC4qrPDC5','price_1MWXVcHCwTRZaEaBMnkKsg7P']
            const spectatorPass = ['price_1MWXYuHCwTRZaEaBSiWX11wl','price_1MIJMGHCwTRZaEaBRkBo8xSM']
            const youthHook = ['price_1MT4MWHCwTRZaEaBYLvV61Z4','price_1MWXPgHCwTRZaEaBGq3UCNge']
            const stdHook = ['price_1MSjUyHCwTRZaEaBMwROq5vM','price_1MPytoHCwTRZaEaBCBkTMYFp']

            let lineItems = this.lastSessionFB.lineItems
            let totalLineItem = []
            lineItems.forEach(
                function(a) {
                    if (pitPass.includes(a.price)) {totalLineItem.push(15*a.quantity)}
                    else if (spectatorPass.includes(a.price)) {totalLineItem.push(5*a.quantity)}
                    else if (youthHook.includes(a.price)) {totalLineItem.push(25*a.quantity)}
                    else if (stdHook.includes(a.price)){totalLineItem.push(50*a.quantity)}
                    else {console.log("not handled!")}
                }
            )
            //let red = this.lastSessionFB.hooks.rd_qt * 25
            //let std = this.lastSessionFB.hooks.st_qt * 50
            var total = 0
            totalLineItem.forEach(x => {total += x})
            return total
        }
    },
    watch: {
        lastSessionFB(newValue) {
            console.log("new value"+ newValue)
            let a = this.lastSessionFB.id
            const pitPass = ['price_1MIJNzHCwTRZaEaBC4qrPDC5','price_1MWXVcHCwTRZaEaBMnkKsg7P']
            const spectatorPass = ['price_1MWXYuHCwTRZaEaBSiWX11wl','price_1MIJMGHCwTRZaEaBRkBo8xSM']
            const youthHook = ['price_1MT4MWHCwTRZaEaBYLvV61Z4','price_1MWXPgHCwTRZaEaBGq3UCNge']
            const standardHooks = ['price_1MPytoHCwTRZaEaBCBkTMYFp','price_1MSjUyHCwTRZaEaBMwROq5vM']

            let lineItems = this.lastSessionFB.lineItems
            let totalLineItem = []
            lineItems.forEach(
                function(a) {
                    if (pitPass.includes(a.price)) {totalLineItem.push(15*a.quantity)}
                    else if (spectatorPass.includes(a.price)) {totalLineItem.push(5*a.quantity)}
                    else if (youthHook.includes(a.price)) {totalLineItem.push(25*a.quantity)}
                    else if (standardHooks.includes(a)){totalLineItem.push(50*a.quantity)}
                    else {console.log("not handled!")}
                }
            )
            //let red = this.lastSessionFB.hooks.rd_qt * 25
            //let std = this.lastSessionFB.hooks.st_qt * 50
            var total = 0
            totalLineItem.forEach(x => {total += x})

            let paid = total

            const lS = db.collection("users").doc(this.user).collection("checkout_sessions").doc(a)
            
            return lS.update({
                stripeSession: this.ck_session,
                paid: paid
            })
            .then(()=> {console.log("user updated with payment!")})
            .catch((error) => {
                console.error("Error updating document: ", error)
            })
        }
    },
    methods: {
       account: function() {
           let path = '/account/'+this.user
           this.$router.push(path) 
       },
       products(price) {
        let product

        const pitPass = ['price_1MIJNzHCwTRZaEaBC4qrPDC5','price_1MWXVcHCwTRZaEaBMnkKsg7P']
        const spectatorPass = ['price_1MWXYuHCwTRZaEaBSiWX11wl','price_1MIJMGHCwTRZaEaBRkBo8xSM','price_1MIJMGHCwTRZaEaBRkBo8xSM','price_1MIJLTHCwTRZaEaBVrHR4tSv']
        const youthHook = ['price_1MT4MWHCwTRZaEaBYLvV61Z4','price_1MWXPgHCwTRZaEaBGq3UCNge']
        const standardHooks = ['price_1MPytoHCwTRZaEaBCBkTMYFp','price_1MSjUyHCwTRZaEaBMwROq5vM']

        if (pitPass.includes(price)) {product = "Pit Pass"}
        else if (spectatorPass.includes(price)) {product = "Spectator Pass"}
        else if (youthHook.includes(price)) {product = "Youth Hook (Reduced Rate)"}
        else if (standardHooks.includes(price)){product = "Hook (Standard Rate)"}
        return product
       }
        

    },
    components: {
        StripeCheckout
    }
}
</script>