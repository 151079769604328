<template>
    <div>
        <v-card class="mx-auto" flat :loading="performingRequest" v-if="loggedIn != 'true'">
            
            <v-card-text>
                <v-form @submit.prevent >
                <v-row>                  <v-col cols="12" sm="5" md="6">
                  <v-text-field v-model="login.emailAddress" label="E-mail address" color="gray">
                      <v-icon slot="prepend" color="red">fal fa-at</v-icon>
                  </v-text-field>
                 </v-col>
                 <v-col cols="12" sm="5" md="6">
                  
                  <v-text-field v-model="login.password" label="Password" color="gray" :type="'password'" :error="login.error" :error-messages="login.error">
                      <v-icon slot="prepend" color="red">fal fa-lock</v-icon>
                  </v-text-field>
                </v-col>
                <v-col cols="12" sm="2"><v-btn class="mt-3" text block dark color="deep-orange darken-3" @click="signin">Login</v-btn></v-col>
            </v-row>
  
                </v-form>
            
            </v-card-text>
        </v-card>
    </div>
</template>

<script>
import {db, auth } from '../../firebaseconfig.js'
import {getAuth,createUserWithEmailAndPassword, signInWithEmailAndPassword } from 'firebase/auth'
import {doc, getDoc, setDoc} from 'firebase/firestore';

export default {
    name: 'adminLogin',
    validations: {
        //name: {required, maxLength:maxLength(10), minLength: minLength(3)},
        //emailAddress: {required, email}
    },
    data() {
        return {
            cp1: 1,
            userId: '',
            performingRequest: false,
            userDetails: {},
            login : {
                emailAddress: '',
                password: '',
                show: true,
                error: null
            }
           
        }
    },
    watch: {
        userId(newUserId) {
            if (newUserId) {
                this.fetchUserData(newUserId)
            }
        }
    },
    firestore() {
        let u = this.user
        return {
            userDetails: db.collection("users").doc(u)
        }
    },
    computed: {
       loggedIn(){
        return sessionStorage.loggedIn
       },
       admin(){
        return sessionStorage.admin
       }
       
    },
    methods: {
       async signin() {

        this.performingRequest = true
        this.login.error = null
        let email = this.login.emailAddress
        let password = this.login.password

            try {
                const userCredential = await signInWithEmailAndPassword(auth, email, password)
                const userId = userCredential.user.uid
                this.userId = userId
                sessionStorage.userId = userId
                sessionStorage.loggedIn = true
        
            } catch (error) {
                console.error("auth error: ", error)
            }

            this.performingRequest = false
           
        },
        async fetchUserData(userId) {
            console.log("watcher to fetch user data triggered!")
            try {
                const userDocRef = db.collection("users").doc(userId);
                const userDoc = await userDocRef.get();

                if (userDoc) {
                    
                    sessionStorage.admin = userDoc.data().admin
                    sessionStorage.userDetails = JSON.stringify(userDoc.data())
                    return userDoc.data() 
                }
                else {
                    return null
                }
            } catch (error) {
                console.error("Error fetching user data: ", error)
            }
        }
    }
}
</script>
