// Import the functions you need from the SDKs you need

// v9 compat packages are API compatible with v8 code
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'firebase/firebase-analytics-compat'
import 'firebase/firebase-functions-compat'
import 'firebase/firestore'
import { query } from 'firebase/firestore';
import _ from 'lodash';


const firebaseConfig = {
  apiKey: "AIzaSyA8PtrJN53ydF4lThw2vVSYzeYBuWa9R-g",
  authDomain: "midwestsuperpull.firebaseapp.com",
  projectId: "midwestsuperpull",
  storageBucket: "midwestsuperpull.appspot.com",
  messagingSenderId: "856719212232",
  appId: "1:856719212232:web:ae4a0b358e2a8393f6effd",
  measurementId: "G-HPD4F8CQL8"
};

firebase.initializeApp(firebaseConfig)

firebase.analytics()
//Vue.prototype.$analytics = firebase.analytics();


const db = firebase.firestore()
const auth = firebase.auth()
const functions = firebase.functions()
//const analytics = getAnalytics(app);


db.enablePersistence()
.catch (function(err) {
    if (err.code == 'failed-precondition') {
        // multiple tabs open
        console.log('Precondition: '+ err)
    }
    else if (err.code == 'unimplemented') {
        console.log('Browser does not support persistence')
    }
});


let sponsors = db.collection("sponsors").where("years","array-contains",2023)
//let classes = db.collection("classes").where("archive","!=", true)


let classes = []
let friday = []
let saturday = []
db.collection("classes").where("archive","!=",true)
    .onSnapshot((querySnapshot)=> {
        querySnapshot.forEach((doc) => {
            let oo = {}
            oo = doc.data()
            oo.id = doc.id
            classes.push(oo)
            if (oo.friday == true) {friday.push(oo)}
            if (oo.saturday == true) {saturday.push(oo)}
        })
       
        var source = querySnapshot.metadata.fromCache ? "local cache" : "server"
        console.log("class came from "+source)
    })
    classes = _.uniqBy(classes, function(c){return c.id})
    friday = _.uniqBy(friday, function(f){return f.id})
    saturday = _.uniqBy(saturday, function(s){return s.id})


let rules = db.collection("rules").doc("2023")
//let users = db.collection("users").orderBy("firstName", "asc").orderBy("lastName", "asc")

let users = []
db.collection("users").orderBy("firstName", "asc")
    .onSnapshot((querySnapshot)=> {
        console.log("users fetch starts now!")
        querySnapshot.forEach((doc) => {
            let oo = {}
            oo = doc.data()
            oo.id = doc.id
            users.push(oo)
            users = _.uniqBy(users, function(u){return u.id})

        })
        
        var source = querySnapshot.metadata.fromCache ? "local cache" : "server"
        console.log("users source", source)
    })

    let currentYearStart = new Date("1/1/2023 00:00:000").valueOf()

let currentYearUsers = db.collectionGroup("checkout_sessions").where("mode","==","payment").where("timestamp",">=",currentYearStart)



let userVehicles = [] 
db.collection("vehicles").where("userRef","!=",null)
    .onSnapshot({includeMetadataChanges:true},(querySnapshot)=> {
    querySnapshot.forEach((doc) => {
        let oo = {}
        oo = doc.data()
        oo.id = doc.id
        userVehicles.push(oo)
       
    })   
    userVehicles = _.uniqBy(userVehicles, function(uv){return uv.id})
    var source = querySnapshot.metadata.fromCache ? "local cache" : "server"
    console.log("vehicle came from "+source)
})

console.log("userVehicles", userVehicles)

let fridayHooks = []
db.collectionGroup("2024-04-05")
    .onSnapshot({includeMetadataChanges:true},(querySnapshot)=> {
    querySnapshot.forEach((doc) => {
        let oo = {}
        oo = doc.data()
        oo.id = doc.id
        fridayHooks.push(oo)
    })   
})
console.log("fridayHooks",fridayHooks)

let saturdayHooks = []
db.collectionGroup("2024-04-06")
    .onSnapshot({includeMetadataChanges:true},(querySnapshot)=> {
    querySnapshot.forEach((doc) => {
        let oo = {}
        oo = doc.data()
        oo.id = doc.id
        saturdayHooks.push(oo)
    })   
})
console.log("saturdayHooks",saturdayHooks)
//let saturdayClasses = db.collection("classes").where("saturday","==", true).where("archive","!=",true)


export {db, auth, sponsors, classes, rules, users, functions, currentYearUsers, userVehicles, friday, saturday, fridayHooks, saturdayHooks}