<template>
<div class="container">
    
    
    <v-toolbar dense flat shaped color="yellow">
        <v-toolbar-title>Users</v-toolbar-title>
        <v-text-field class="mt-2" v-model="search" prepend-icon="mdi-magnify" single-line></v-text-field>
        <add-user/>
        
        
    </v-toolbar>
    <user v-for="u in filteredUsers" :key="u.id" :userDetails="u" class="mt-2" :classes="classes"/>
</div>
</template>
<script>
import {db} from '../../firebaseconfig'
import user from '../components/user.vue'
import addUser from '../components/addUser.vue'
import _ from 'lodash'

export default {
    name: 'manageClasses',
    data() {
        return {
            users: [],
            search: '',
            classes: []
        }
    },
    props: {
        userDetails: Object
    },
    computed: {
        groupByClass() {
            let paid = _.filter(users,)
            let c = _.groupBy()
        }
    },
    components: {'user':user, 'addUser': addUser},
    firestore() {
        return {
            users: db.collection('users').orderBy('firstName','asc'),
            classes: db.collection('classes').orderBy('name','asc')
        }
        
    }
}
</script>