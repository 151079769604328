<template>
<div>
    <v-row>
    <v-col cols="1"><v-avatar class="p-0" :color="colors" size=36>{{order}}</v-avatar></v-col>
    <v-col class="mr-auto" cols=6> <v-icon>mdi-tractor-variant</v-icon> &nbsp;{{vname}}<br><span class="text-capitalize" v-if="!hook.details.driver">{{hook.details.person.fullName}}</span><span class="text-capitalize" v-else>{{hook.details.driver}}</span></v-col>
    
    
    <v-col v-if="!hook.dq && !hook.pullOff && hook.distance" class="noprint">
        {{hook.distance}}ft.
        </v-col>
        <v-col v-if="!hook.dq && hook.pullOff" class="noprint">
        Total: {{Number(hook.distance) + Number(hook.pullOff.distance)}}ft.<br>
        1st Hook: {{Number(hook.distance)}},<br>
        2nd Hook: {{Number(hook.pullOff.distance)}}
        </v-col>
        <v-col v-if="!hook.dq && hook.heat > 0" class="noprint">
        1st Hook: {{Number(hook.distance)}}<br>
        <span v-if="hook.finalHeat">2nd Hook: {{Number(hook.finalHeat.distance)}}</span>
        </v-col>
        <v-col v-if="!hook.dq">{{hook.speed}} mph
    </v-col>
    <v-col v-if="hook.dq" class="noprint">
        {{hook.dqReason}}
    </v-col>
    <v-col v-if="hook.weight > 0" style="line-height: 3em;">{{ hook.weight }} lbs</v-col>
    <v-col v-if="!edit && admin" class="ml-auto noprint" cols=1 >
        <v-btn  @click.stop="editHook" color="primary" fab elevation="0" x-small class="mt-1"><v-icon>mdi-pencil</v-icon></v-btn>
    </v-col>
    <v-col v-if="!weightCheck && weightAdmin" class="ml-auto noprint" cols=1 >
        <v-btn  @click.stop="editWeight()" color="secondary" fab elevation="0" x-small class="mt-1"><v-icon>mdi-weight-pound</v-icon></v-btn>
    </v-col>
        
    </v-row>
    <v-row v-if="weightCheck && weightAdmin">
        <v-col cols="10"><v-text-field type="number" label="Tractor Weight in Lbs" block v-model="weight" suffix="lbs"></v-text-field></v-col>
        <v-col><v-btn @click="manageWeight" outlined color="green" block>Save</v-btn></v-col>
    </v-row>
    <v-row v-if="edit && admin" class="mb-10">
        <v-col v-if="admin">
            <v-row>
                <v-col>
        <v-switch v-model="ddriver" label="Different Driver"></v-switch>
                </v-col>
                <v-col>
        <v-switch v-model="dq" label="DQ?"></v-switch>
                </v-col>
                <v-col>
        <v-text-field v-model="id" label="Hook"></v-text-field>
                </v-col>
            </v-row>
        <v-text-field label="Driver" block v-model="driver" v-if="ddriver"></v-text-field>
        <v-text-field label="DQ Reason" block v-model="dqReason" v-if="dq"></v-text-field>
        <v-row v-if="!dq">
        <v-col><v-text-field type="number" label="Feet" block v-model="distance.feet" suffix="ft"></v-text-field></v-col>
        <v-col><v-text-field type="number" label="Speed" block v-model="speed" suffix="mph"></v-text-field></v-col>
        </v-row>
        <v-row v-if="Number(hook.distance) >= fullPull">
        <v-col><v-text-field type="number" label="Pull-off Feet" block v-model="pullOff.distance" suffix="ft"></v-text-field></v-col>
        <v-col><v-text-field type="number" label="Pull-off Speed" block v-model="pullOff.speed" suffix="mph"></v-text-field></v-col>
        </v-row>
        <v-row>
            <v-col>
                <v-btn text block @click.stop="cancel">Cancel</v-btn>
            </v-col>
            <v-col>
                <v-btn text block @click.stop="deleteHook" color="red" ><v-icon>mdi-delete</v-icon> Delete</v-btn>
            </v-col>
            <v-col>
                <v-btn class="primary" block @click.stop="updateHook">Save Changes</v-btn>
            </v-col>
        </v-row>
    </v-col>
    </v-row>
</div>
</template>
<script>
import {db} from '../../firebaseconfig'
import { userStatus} from '../store/index'
export default {
name: 'hookDetail', 
data() {
    return {
        user: {},
        caseSensitive: false,
        ddriver: false,
        dq: false,
        dqReason: '',
        distance: {feet:null,inches: null},
        speed: null,
        edit:false,
        weightCheck: false,
        driver: '',
        id: null,
        pullOff: {
            distance: null,
            speed: null,
            dq: false,
            dqReason: ''
        },
        weight: null
    }
},
props: {
    hook:Object,
    order:Number,
    admin:Boolean,
    day:String,
    fullPull:Number
},
firestore() {
    let h = this.hook.user
    return {
        user: db.collection('users').doc(h)
    }
},
computed: {
    weightAdmin() {
        return userStatus.weighIn
    },
    vname() {
        return (this.hook.name).toUpperCase()
    },
    fullname () {
        let n = this.user.firstName+' '+this.user.lastName
        return n
    },
    signedInUser() {
        return this.$parentDetails.user
    },
    isFullPull(){
           if (isNaN(this.fullPull)) {
               return false
           } else {
               if (this.fullPull <= this.hook.distance) {return true}
               else {return false}
           }
       },
    colors(){
        if (this.isFullPull) {return 'light-green lighten-2'}
        else {return 'grey lighten-4'}
    }
},
methods: {
    editWeight(){
        if (this.hook.weight) {this.weight = this.hook.weight}
        this.weightCheck = true
    },
    manageWeight(){
        let uH = {weight:this.weight}
        var hookRef = db.collection("classes").doc(this.hook.classId).collection(this.day).doc(this.hook.id)
        var userHookRef = db.collection("vehicles").doc(this.hook.id).collection(this.day).doc(this.hook.classId)
        return hookRef.update(uH)
            .then(()=> {
                userHookRef.update(uH).then(()=>{console.log("user hooks updated with new weight!",uH)}).catch((error)=>{console.log('userHookRef weight',error)})
                this.weightCheck = !this.weightCheck
            })
            .catch((error) => {
                alert('hookRef',error)
            })
    },
    editHook() {
        if (this.hook.driver) {this.driver = this.hook.driver
                                this.ddriver = true}
        if (this.hook.distance) {this.distance.feet = Number(this.hook.distance)}
        if (this.hook.speed) {this.speed = Number(this.hook.speed)}
        if (this.hook.dq) {
            this.dq = this.hook.dq
            this.dqReason = this.hook.dqReason
        }
        if (this.isFullPull && this.hook.pullOff) {
           if (this.hook.pullOff.distance) {this.pullOff.distance = Number(this.hook.pullOff.distance)}
           if (this.hook.pullOff.dq) {this.pullOff.dq = this.hook.pullOff.dq}
           if (this.hook.pullOff.dqReason) {this.pullOff.dqReason = this.hook.pullOff.dqReason}
        }

        this.id = this.hook.hook
        this.edit = !this.edit
    },
    updateHook() {
        let uH = {}
        let hookInt = parseInt(this.id)
        if (this.dq) {uH.dq = true, uH.dqReason = this.dqReason} else {uH.dq = false, uH.dqReason = this.dqReason}
        if (this.ddriver != "" || this.ddriver == false) {uH.driver = this.driver} else {uH.driver = this.hook.driver}
        if (this.speed) {uH.speed = this.speed}
        if (this.distance.feet) {uH.distance = this.distance.feet}
        if (this.isFullPull){
            uH.pullOff = this.pullOff
        }
        if (hookInt != this.hook.hook) {uH.hook = hookInt}

        console.log(uH)
        var hookRef = db.collection("classes").doc(this.hook.classId).collection(this.day).doc(this.hook.id)
        var userHookRef = db.collection("vehicles").doc(this.hook.id).collection(this.day).doc(this.hook.classId)
        return hookRef.update(uH)
            .then(()=> {
                userHookRef.update(uH).then(()=>{console.log("user hooks updated!",uH)}).catch((error)=>{console.log('userHookRef',error)})
                this.edit = !this.edit
            })
            .catch((error) => {
                alert('hookRef',error)
            })
    },
    deleteHook() {
        let uH
        let deleted = new Date()
        
        uH = {
            //class: this.hook.class,
            classId: this.hook.classId,
            created: this.hook.created,
            date: this.hook.date,
            details: this.hook.details,
            distance: this.distance.feet,
            driver: this.driver,
            hook: this.id,
            id: this.hook.id,
            name: this.hook.name,
            speed: this.speed,
            //user: this.hook.user,
            deleted: deleted
        }

        console.log(uH)

        var hookRef = db.collection("classes").doc(this.hook.classId).collection(this.day).doc(this.hook.id)
        var userHookRef = db.collection("vehicles").doc(this.hook.id).collection(this.day).doc(this.hook.classId)
        var deletedHooks = db.collection("deletedHooks")
        let text = "Are you sure you want to delete this hook?"
        if (confirm(text) == true) {
        
        return deletedHooks.add(uH).then((docRef) => {
            console.log("Hook added to deleted collection", docRef.id)
            this.edit = !this.edit
            return hookRef.delete().then(()=> {console.log("Deleted in vehicle collection")
            return userHookRef.delete().then(()=> {console.log("Deleted in user collection.")}).catch((error)=> {console.log("Couldn't delete in user collection.",error)})
            }).catch((error)=>{console.log("Couldn't delete in vehicle collection",error)})
        })}
        else {
            console.log("Canceled hook deletion.")
        }
        
    },
    cancel() {
        this.edit = !this.edit
    }
}
}
</script>

<style>
@media print {
    
    body {
      overflow: auto;
      height: auto;
    }

    .pgBreak {
      page-break-after: always;
      margin-top: 50px;
    }
    .toprint {
        display: block !important;
    }
    .noprint {
      display: none !important;
    }

    .pullDetails {
      position: fixed;
      top: 0;
      width: 100%;
    }

  }
</style>